<template>
    <div class="goverment">
        <template v-if="detailslook">
          <div class="page" :style="{ backgroundImage: 'url(' + banner + ')' }"></div>
          <div>
            <div class="contect">
              <div class="titletop">当前位置：数字政府>{{ title }}</div>
              <div class="line"></div>
              <div class="flex introduce">
                <div>
                  <div class="options">
                      <div class="chanpintext">产品服务</div>
                      <div @click="goComponent(item.id)" v-for="item in productServiceDtoList"
                           :class="[id == item.id ? 'grenying' : '','newname']">
                        <img class="uiconw" :src="id == item.id ?item.checkedIcon:item.noCheckedIcon" alt="">
                        {{ item.name }}
                        <br/>
                      </div>
                    <div style="padding-bottom: 30px; white-space: nowrap; margin-top: 20px;">
                      <div class="chanpintext" @click="goCasetwo">
                        应用案例
                      <template v-if="yingtoal>0">（{{yingtoal}}）</template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="optionText" v-if="Show">
                  <div class="title">{{introduce.name}}</div>
                  <div class="line2"></div>
                  <div v-html="introduce.introduce" class="introduce"></div>
                </div>
                <!--应用案例-->
                <div class="optionText" v-else>
                  <div class="title">
                    <span>应用案例</span>
                    <div class="biaoqian">
                      <span @click="qiecolor(99,'全部')" :class="[qiecolort == 99 ?'bluecolor':'']">全部</span>
                      <span @click="qiecolor(index,item.name)" :class="[qiecolort == index ?'bluecolor':'']" v-for="(item,index) in productServiceDtoList" :key="item.id">{{item.name}}</span>
                    </div>
                  </div>
                  <div class="flexDiv">
                      <template  v-for="item in applicationCasesList">
                        <div class="applicationCasesDiv" @click="yinyong(item.id)">
                          <img :src="item.pic" alt="">
                          <div class="detailbomtext">
                            {{item.name}}
                            <div>
                              <span class="bomspan">{{item.productServiceName}}</span>
                            </div>
                          </div>
                        </div>
                      </template>
                  </div>


                </div>

              </div>

            </div>
          </div>
        </template>
      <template v-if="!detailslook">
        <div class="detailbottom">
          <div class="titletop nowhei">当前位置：数字政府>{{ title }} <span @click="backlist" class="backlist">返回</span></div>
          <div class="detailrigh" v-html="detailsin"></div>
        </div>
      </template>
    </div>
</template>

<script>

export default {
    data() {
      return {
        detailsin: '',
        detailslook: true,
        fuwen: '<h2>I am Example</h2>',
        qiecolort: 99,
        picId: "",
        num: 1,
        banner: '',
        productServiceDtoList: [],
        introduce: '',
        page: 1,
        limit: 10,
        caseList: [],
        list: [],
        show: true,
        title: "产品服务",
        id: '',
        applicationCasesList: [],
        shaiying: [],
        Show: true,
        numIndex: '',
        yingtoal: 0,
        querychild: '',
      }
    },
  mounted() {

  },
  created() {
        this.getData()
        this.goCase()
        this.querychild = this.$parent.parentid
      let routeId = this.$route.query.id;
      // if(routeId){
      //   this.goComponent(routeId);
      // }
    },
    watch: {

        $route(to, from) {
          this.goComponent(this.querychild);

          // if (this.$route.query.id) {
          //       this.goComponent(this.$route.query.id);
          //   }else{
          //     this.goComponent(this.querychild);
          //   }
        },
      productServiceDtoList(){
        this.goComponent(this.querychild);

      },
      detailslook(){
        // console.log('更改值',this.detailslook)
        this.goComponent(this.querychild);
      },
      querychild(){
          this.goComponent(this.querychild);
      },
    },
    methods: {
      backlist(){
        this.detailslook = true
        this.$emit("getUser",true)
      },
      yinyong(val){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.$emit("getUser",false)
        this.detailslook = false
        this.$http({
          url: 'productApi/queryApplicationCasesDetail?applicationCasesId='+ val +'',
          method: 'post',
        }).then((res) => {
        this.detailsin = res.data.introduce
        })
        // this.$router.push({
        //   path: '/details',
        //   query: {
        //     id: val
        //   }
        // })
      },
      change(index) {
        this.numIndex = index
      },
      qiecolor(val,name) {
        this.qiecolort = val
        if(name == '全部'){
          this.applicationCasesList = this.shaiying
        }else{
          let arr =this.shaiying.filter(item=>{
            return item.productServiceName == name
          })
          this.applicationCasesList = arr
        }
      },
      // 产品服务
      getData() {
        this.$http({
          url: 'productApi/queryProductService?type=1',
          method: 'post',
        }).then((res) => {
          this.banner = res.data.banner
          this.productServiceDtoList = res.data.productServiceList
          if (this.productServiceDtoList&&this.productServiceDtoList.length > 0 && this.querychild == '') {
            this.goComponent(this.productServiceDtoList[0].id)
          }
        })
      },
      // 点击产品服务下级
      goComponent(val) {
        this.id = val;
        this.title = '产品服务'
        this.show = true
        this.Show = true
        let that = this
        if(this.productServiceDtoList.length > 0){
          let arr = this.productServiceDtoList.filter(item => (item.id == val));
          this.introduce = arr[0]?arr[0]:this.productServiceDtoList[0]
          this.id=this.id==''?this.productServiceDtoList[0].id:this.id
          // console.log('arr[0]',arr[0],'this.productServiceDtoList',this.productServiceDtoList)
          // console.log('val出发了没有',val,'触发了this.id',this.id)

        }

      },
      goCasetwo(){
        this.title = '应用案例'
        this.show = false
        this.Show = false
        this.id = ''
        this.qiecolor(99,'全部')
      },
      goCase() {
        this.$http({
          url: 'productApi/queryApplicationCases?type=1',
          method: 'post',
        }).then((res) => {
          this.applicationCasesList = res.data
          this.shaiying=res.data
          this.yingtoal = res.count

        })
        // this.$http({
        //     url: 'productApi/queryApplicationCasesDetail?type=1&applicationCasesId=' + val,
        //     method: 'post',
        // }).then((res) => {
        //     this.applicationCasesList = res.data
        // })
      }
    },
}


</script>

<style scoped lang="scss">
//666
.bomspan{
  box-sizing: border-box;
  background: #F5F5FB;
  border-radius: 2px;
  color: #64728A;
  font-size: 14px;
  padding: 7px;
  margin-top: 15px;
  display: inline-block;
}
.detailbomtext{
  width: 100%;
  box-sizing: border-box;
  padding: 14px 13px;
  font-size: 18px;
  color: #4C5462;
}
.applicationCasesDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  margin-left: 3.3%;
  height: auto;
  border-radius: 10px;
  border: 1px solid #E9EDF1;
  margin-top: 40px;
  cursor: pointer;
  //transform:scale(1);
  transition: all 0.3s;
  img{
    width: 100%;
    height: 220px;
    border-radius: 10px 10px 0 0;
  }
}
.applicationCasesDiv:hover{
  transform:scale(1.1);
  -webkit-box-shadow: #ccc 0px 10px 10px;
  -moz-box-shadow: #ccc 0px 10px 10px;
  box-shadow: #ccc 0px 10px 10px;
  transition: all 0.3s;

}

.applicationCasesDiv:nth-child(3n+1){
  margin-left: 0 !important;
}
.grenying{
  background: linear-gradient(90deg, #08215A, #092D81) !important;
  border: 1px solid #092D81;
  color: white !important;
}
.newname{
  width: 100%;
  height: 55px;
  line-height: 55px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  margin-top: 12px;
  box-sizing: border-box;
  padding: 0 16px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  .uiconw{
    width: 30px !important;
    height: auto;
    vertical-align: middle;
    margin-right: 17px;
  }
}
.chanpintext{
  margin-bottom: 10px;
  color: #08215A !important;
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
}
.backlist{
  float: right;
  font-size: 14px;
  cursor: pointer;
}
.detailrigh{
  box-sizing: border-box;
  /deep/ img{
    max-width: 100%;
  }
  //padding-left: 120px;
}
.detailbottom{
  width: 60%;
  min-height: 800px;
  //background: pink;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 120px;
}
.bluecolor{
  background: #F1F5FF;
  border: 1px solid #3E77FF !important;
}
.biaoqian{
  display: inline-block;
  vertical-align: 3px;
  span{
    display: inline-block;
    width: 100px;
    box-sizing: border-box;
    padding: 0 5px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #D2D3E4;
    border-radius: 6px;
    font-size: 14px;
    margin-left: 15px;
    cursor: pointer;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    float: left;
    margin-bottom: 5px;
  }
  span:hover{
    background: #F1F5FF;
    border: 1px solid #3E77FF;
    transition:0.3s;
  }
  span:nth-child(1){
    //margin-left: 24px;
  }
}
.titletop {
  height: 30px;
  line-height: 30px;
  color: #A0A8B5;
  font-size: 14px;
  margin: 15px 0;
}
.nowhei{
  margin: 40px 0 40px -120px;
}
//********
.introduce /deep/ img {
    max-width: 100% !important;
}

.flexDiv {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    //justify-content: space-around;

}
.applicationCasesList {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    width: 30%;
    .applicationCasesDiv {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    img {
        width: 389px;
        height: 240px;
        border: 5px solid #FFFFFF;
    }

    .span {
        margin-top: 6px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #4C5462;
    }
    .applicationCasesDiv img:hover{
        border: 5px solid gray;
    }

}

//.h3 {
//    font-size: 18px;
//    font-family: Alibaba PuHuiTi;
//    font-weight: 700;
//    color: #4C5462;
//}

.h3Line {
    margin-top: -8px;
    height: 8px;
    width: 30px;
    background-color: #FFBA00;
}



.introduceContect {
    margin-top: -30px;
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    cursor: pointer;

    .name {
        font-size: 22px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 24px;
    }

    .line {
        width: 26px;
        height: 4px;
        background-color: #ffffff;
    }

    .text {
        font-size: 16px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        width: 90%;
        display: none;
    }
}

.introduceContect:hover {
    .name {
        margin-top: 50px !important;
    }

    justify-content: flex-start;

    .icon {
        display: none;
    }

    .text {
        display: block;
    }
}

.flex {
    display: flex;
}

.optionText {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;

    .Image {
        margin-top: 50px;
        width: 100%;
        justify-content: space-between;
        margin-left: -100px;


        .line {
            width: 26px;
            margin-bottom: 10px;
        }

        .name {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .title {
        width: 80%;
        height:auto;
        font-size: 24px;
      font-weight: bold;
        font-family: Alibaba PuHuiTi;
        //font-weight: 500;
        color: #4C5462;
        white-space: nowrap;
      span:nth-child(1){
        float: left;
      }
    }


}


.goverment {
    margin-bottom: 150px;
}

.page {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
}

.contect {
    width: 85%;
    margin-left: 15%;
}



.line {
    width: 80%;
    height: 1px;
    background-color: #EBEEF3;
}

.introduce {
    margin-top: 30px;
}

.options {
    width: 280px;
    //background: #F5F6FA;
    border-radius: 4px;
    cursor: pointer;

    .Line {
        width: 23%;
        height: 1px;
        background-color: #4C5462;
    }

    .h3 {
        margin-left: 20px;
    }

    .h3Line {
        margin-left: 20px;
    }

    .name {
        line-height: 40px;
        margin-left: 30px;
        font-size: 14px;
        display: inline-block;
        display: inline;
        width: auto;
        height: auto;
    }
}



.line2 {
    width: 100%;
    height: 1px;
  margin-top: 15px;
    background-color: #EBEEF3;
}

.optionTitle {
    margin-top: 20px;
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #4C5462;
    line-height: 24px;


}
</style>
