<template>
   <div>
<!--    <video src="../assets/image/202209011354.mp4" autoplay muted class="video" v-if="show" id="video"></video>-->
<!--    <div class="contect" :style="{ backgroundImage: 'url(' + allmes.indexBannerList[0].banner + ')' }">-->
<!--    </div>    -->



     <div class="contect">
       <el-carousel
           :autoplay="autoplay"
           :interval="interval"
           :initial-index="initialIndex"
           ref="carousel"
           @change="onChange"
       >
         <el-carousel-item v-for="item in banner" :key="item.id">
           <video-player
               class="video-player vjs-custom-skin"
               style="width: 100%;height: 100%;"
               v-if="item.banner.indexOf('mp4') !== -1"
           ref="videoPlayer"
           :playsinline="true"
           :options="playerOptions"
           :x5-video-player-fullscreen="true"
           @play="onPlayerPlay($event)"
           @ended="onPlayerEnded($event)"
           @loadeddata="onPlayerLoadeddata($event)"
           >
           </video-player>
           <img class="banbg" style="width: 100%;height: 100%;" v-else :src="item.banner" />
         </el-carousel-item>
       </el-carousel>
    </div>




      <div class="jumpone" v-if="textlook">
        <p>为客户提供满意的产品和服务</p>
        <p>提供最好的信息化服务 / 坚守诚信的合作准则 / 拥有最可靠的技术支撑</p>
      </div>
     <div class="jump flex">
       <div class="choose flex" @click="jumpTo('government')">
         <img class="chooseImg" src="../assets/inone.png" alt="">
         <div class="name">
           <p>数字城市</p><p>打造数字社会、驱动数字生态、引领数字发展</p>
         </div>
         <img src="../assets/shux.png" class="shuximg" alt="">
       </div>
       <div class="choose flex" @click="jumpTo('industrial')">
         <img class="chooseImg" src="../assets/intwo.png" alt="">
         <div class="name">
           <p>数字工业</p><p>通过自动化、智能化的生产方式降本增效、助力转型</p>
         </div>
         <img src="../assets/shux.png" class="shuximg" alt="">
       </div>
       <div class="choose flex" @click="jumpTo('building')">
         <img class="chooseImg" src="../assets/inthree.png" alt="">
         <div class="name">
           <p>数字仿真</p><p>采用数字计算机技术对系统进行模拟和计算</p>
         </div>
       </div>
     </div>
   </div>
</template>

<script>
const axios = require("axios");
export default {
    data() {
        return {
          textlook:true,
            picIndex: '',
            show:true,
            government:true,
            industrial:true,
            building:true,
          allmes:'',
          autoplay: true, //自动播放
          interval: NaN, //轮播时常
          initialIndex: 0, //从下标第几个开始轮播
          duration: NaN, //计算总时长
          banner: [],    //从后端获取来的轮播图数据

          //vue-video-play  播放器默认的设置
          playerOptions: {
            autoplay: true, //如果true,浏览器准备好时开始回放。实现自动播放，autoplay、muted都设置为true
            muted: true, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                src: "这里是你视频的地址", //url地址
              },
            ],
            width: document.documentElement.clientWidth, //播放器宽度
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          },
        }
    },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
    created() {
        this.getDate()
    },
    mounted() {
    let that = this
    // var elevideo = document.getElementById("video");
    // elevideo.addEventListener('ended', function () { //结束
    //         that.show = false
    // }, false);
    },
    methods: {
        getDate() {
          let that=this
          this.$http({
            url:'indexApi/queryIndexInfo',
            method:'post',
          }).then((res)=>{
            // console.log('resindex',res)
            this.allmes = res.data
            this.banner = res.data.indexBannerList
            if(this.banner.length == 1 && this.banner[0].banner.indexOf("mp4")){
              this.playerOptions["sources"][0]["src"] = this.banner[0].banner; //把获取来的视频赋值给sources的src
            }
            // this.picIndex = res.data.data.picIndex
          })


        },
        jumpTo(val) {
            this.$router.push({
                path: val,
            })
            if (val == 'government') {
                this.government = false
            }else if(val == 'industrial'){
                this.industrial = false
            }else{
                this.building = false
            }
        },
      // 监听轮播图改变
      onChange(index) {
        // 如果是视频,就等播放完再进行下一个
        if (this.banner[index].banner.indexOf("mp4") != -1) {
          this.playerOptions["sources"][0]["src"] = this.banner[index].banner; //把获取来的视频赋值给sources的src
          this.$refs.videoPlayer[0].player.src(
              this.playerOptions["sources"][0]["src"]
          ); // 重置进度条,再次轮播时让视频重新播放
          this.interval = this.duration * 1000;
          this.autoplay = false;
          this.textlook = false
        }else{
          this.textlook = true
        }
      },
      onPlayerEnded(player) {
        if(this.banner.length == 1 && this.banner[0].banner.indexOf("mp4")){
          this.playerOptions["loop"] = true //如果只有一个视频，则循环播放
        }
        //视频播放停止时候触发的事件
        this.autoplay = true;
        this.interval = 3000;
      },
      onPlayerLoadeddata(player) {
        // 获取资源总时长
        this.duration = player.cache_.duration;
      },
      onPlayerPlay(player) {
        player.play();
      },

    }
}
</script>

<style scoped lang="scss">
.banbg{
  height: 100%;
}
/deep/ {
  //视频
  /* 进度条 */
  .vjs-tech{
    object-fit:fill;
    overflow:hidden;
  }
  .vjs-control-bar,.vjs-control,.vjs-progress-control {
    display: none !important;
  }
  .video-js{
    height: 100% !important;
    padding-top: 0 !important;
  }
  .el-carousel{
    height: 100%;
    .el-carousel__container{
      height: 100%;
    }
  }
  .el-carousel__arrow--left{
    display: none;
  }
  .el-carousel__arrow--right{
    display: none;
  }
  .el-carousel__indicators--horizontal{
    display: none;
  }
}
body {
    width: 100%;
}

.flex {
    display: flex;
}
.chooseImg{
    width: 60px;
    height: 60px;
  margin-right: 20px;
}
.video{
    width: 100%!important;
    position: absolute;
    height: 1020px;
    z-index: 1;
    object-fit: fill;
}
.contect {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 1020px;
    width: 100%;
    position: relative;
  /deep/ .el-carousel__container{
    height: 1020px !important;
  }
    // background-size: cover;
    // -webkit-background-size: cover;
}
.jumpone{
  position: absolute;
  top:-380px;
  left: 0;
  right: 0;
  bottom: 0;
  margin:auto;
  height: max-content;
  text-align: center;
z-index: 99;
  p:nth-child(1){
    font-size: 72px;
    font-family: Alibaba PuHuiTi;
    color: white;
    margin-bottom: 40px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  p:nth-child(2){
    font-size: 24px;
    font-family: Alibaba PuHuiTi;
    color: white;
    font-weight: 300;
  }
}
.jump {
    //margin: 0 auto;
    width: 53%;
    height:max-content;
  position: absolute;
  top: 180px;
  left: 0;
  right: 0;
  bottom: 0;
  margin:auto;
    //align-items: center;
    //justify-content: space-between;

}

.jump {
  .choose{
    cursor: pointer;
    width: 33%;
    position: relative;
    .shuximg{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .choose:not(:last-child){
    //border-right: 1px solid red;
  }
  .choose:nth-child(2){
    width: 36%;
  }
}

.choose {
    z-index: 2;
  flex-direction:row;
    justify-content: center;
    align-items: center;
    //font-size: 24px;
    color: white;
  background:rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 17px 3px;
    min-height: 88px;
    font-weight: 500;
    //margin-top: 150px;
    position: relative;
}
.choose:active{
    background-color:rgba(104, 203, 255, 0.5);
    color: #ffffff;
    //.chooseImg{
    //    background: url(../assets/image/qu_bai.png)no-repeat;
    //}
}
.name {
    width: 53% !important;
    text-align: left;
  p{margin: 0;}
  p:nth-child(1){
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 5px;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  p:nth-child(2){
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
</style>