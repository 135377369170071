import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import request from '../src/utils/request'
// import 'normalize.css/normalize.css'
import './permission'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(VideoPlayer)

/* 引入swiper */
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// Vue.use(VueAwesomeSwiper /* { default options with global component } */)



Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(VueRouter)

Vue.prototype.$http = request;
// Vue.prototype.$url = process.env.NODE_ENV === 'development' ? 'https://jwsxgc.suchengkeji.cn' : 'https://jwsxgc.suchengkeji.cn';
Vue.prototype.$url = process.env.NODE_ENV === 'development' ? 'https://jwsxgc.suchengkeji.cn' : 'https://jsdckj.cn';


const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
