import axios from 'axios'
import {Message} from 'element-ui'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? '/api' : 'https://jwsxgc.suchengkeji.cn/HtProductWebsite/api/', // api 的 base_url
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : 'https://jsdckj.cn/HtProductWebsite/api/', // api 的 base_url
  timeout: 3000000 // request timeout
})

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    if(res.code === 10200||res.state === 10200||(!res.code&&!res.state) ){
      return res
    }else {
      Message({
        message: res.message,
        type: 'error',
        duration: 2 * 1000,
        offset: 200
      })
    }

    return Promise.reject(res)
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 2 * 1000,
      offset: 200
    })
    return Promise.reject(error)
  }
)

export default service
