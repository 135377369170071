<template>
    <div>
        <div class="page" :style="{ backgroundImage: 'url(' + banner + ')' }"></div>
<!--        <div class="contect">-->
<!--            <div class="title">当前位置：关于点创>{{ title }}</div>-->
<!--            <div class="line"></div>-->
<!--            <div class="flex">-->
<!--                <div style="margin-top: 30px;">-->
<!--                    <div class="titleImg">-->
<!--                        点创科技-->
<!--                    </div>-->
<!--                    <div class="choose" @click="to('companyIntro')"-->
<!--                        :style="[{ backgroundColor: (color == 'companyIntro' ? '#1C3B89' : '#EBEEF3') }, { color: (color == 'companyIntro' ? '#ffffff' : '#4C5462') }]">-->
<!--                        公司简介</div>-->
<!--                    <div class="choose" @click="to('leaderMessage')"-->
<!--                        :style="[{ backgroundColor: (color == 'leaderMessage' ? '#1C3B89' : '#EBEEF3') }, { color: (color == 'leaderMessage' ? '#ffffff' : '#4C5462') }]">-->
<!--                        领导寄语</div>-->
<!--                    <div class="choose" @click="goHonor('goHonor')"-->
<!--                        :style="[{ backgroundColor: (color == 'goHonor' ? '#1C3B89' : '#EBEEF3') }, { color: (color == 'goHonor' ? '#ffffff' : '#4C5462') }]">-->
<!--                        荣誉资质</div>-->
<!--                    <div class="choose" @click="to('corporateCulture')"-->
<!--                        :style="[{ backgroundColor: (color == 'corporateCulture' ? '#1C3B89' : '#EBEEF3') }, { color: (color == 'corporateCulture' ? '#ffffff' : '#4C5462') }]">-->
<!--                        企业文化</div>-->

<!--                </div>-->

<!--                <div class="optionText">-->
<!--                    <div v-if="show" class="right">-->
<!--                        <div v-if="companyShow">-->
<!--                            <div class="h1">公司简介</div>-->
<!--                            <div class="companyIntroLine"></div>-->
<!--                            <div class="flex">-->
<!--&lt;!&ndash;                                <div><img :src="list.companyIntroPic" alt=""></div>&ndash;&gt;-->
<!--                                <div class="company">-->
<!--                                    <div class="companyName">{{list.name}}</div>-->
<!--                                    <div class="companyIntroSynopsis">{{list.companyIntroSynopsis}}</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--&lt;!&ndash;                            <div v-html="list.companyIntro"></div>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div v-if="leaderShow">-->
<!--                            <div class="h1">领导寄语</div>-->
<!--                            <div class="companyIntroLine"></div>-->
<!--                            <div class="flex">-->
<!--                                <div><img src="../assets/image/lingdao.png" alt=""></div>-->
<!--                                <div class="company">-->
<!--                                    <div class="companyName">江苏点创信息科技有限公司</div>-->
<!--                                    <div class="companyIntroSynopsis">-->
<!--                                        江苏点创信息科技有限公司是专业从事信息化建设的高新技术企业，是淮海经济区较具规模同时具有软件开发、智慧城市解决方案、建筑智能化服务的企业，在信息化建设领域具有较强的行业影响力和良好的用户口碑。-->
<!--                                        公司以“提供最好的信息化服务”为使命，始终保持创新、热情的企业文化，拥有精干、高效、敬业的技术队伍，丰富的经验、专业的技术、优质的服务，保证了公司在同行和用户中享有良好的知名度和美誉度。-->
<!--                                        随着公司的不断发展，公司仍将坚持以市场为出发点，以满足客户的需求为己任，立足于信息化、智能化及其相关的服务产业，用我们的智慧、信誉和态度服务社会！</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div v-if="CultureShow">-->
<!--                            <div class="h1">企业文化</div>-->
<!--                            <div class="companyIntroLine"></div>-->
<!--                            <div class="corporateCultureList" v-for="(item,index) in list.corporateCulturesList">-->
<!--                                <div class='card'>-->
<!--                                    <div class="scale" :style="{ backgroundImage: 'url(' + item.pic + ')' }"></div>-->
<!--                                    <div :class="index%2==0 ?'cultureContentLeft' : 'cultureContentRight'">-->
<!--                                        <div v-if="index%2==0">-->
<!--                                            <span class="cultureName"-->
<!--                                                :style="{marginRight:(index%2==0 ? '20px' : '')}">{{item.name}}</span>-->
<!--                                            <span class="cultureContent">{{item.content}}</span>-->
<!--                                            <div-->
<!--                                                :style="[{borderBottom:(index%2==0 ? '4px solid #FFFFFF':'')},{width:(index%2==0 ? '72px':'')}]">-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div v-else>-->
<!--                                            <span class="cultureContent"-->
<!--                                                :style="{marginRight:(index%2 !==0 ? '20px' : '')}">{{item.content}}</span>-->
<!--                                            <span class="cultureName">{{item.name}}</span>-->
<!--                                            <div :style="[{borderBottom:(index%2 !==0 ? '4px solid #FFFFFF':'')},{width:(index%2 !==0 ? '72px':'')}]"-->
<!--                                                class="cultureLine"></div>-->
<!--                                        </div>-->

<!--                                    </div>-->
<!--                                </div>-->

<!--                            </div>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                    <div v-if="Show">-->
<!--                        <div class="h1">荣誉资质</div>-->
<!--                        <div class="companyIntroLine"></div>-->
<!--                        <div class="honorList">-->
<!--                            <div v-for="(item, index) in honorList" :key="index" class="honorListDiv">-->
<!--                                <div class="applicationCasesDiv">-->
<!--                                    <img :src="item.pic" alt="">-->
<!--                                    <div class="name">{{ item.name }}</div>-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->


<!--                    </div>-->


<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
      <div class="newcontecttop">
        <img src="../assets/dianchuang.png" alt="">
      </div>
      <div class="newcontect">
          <div class="topone">
            <p style="font-weight: bold;">关于我们</p>
            <p>About &nbsp;Us</p>
            <p>{{aboutUs.aboutUs}}</p>
          </div>
        <p class="lingdaop">领导寄语</p>
        <div class="toptwo">
          <img :src="aboutUs.leaderMessagePic" alt="">
          <div class="toptworight">
            <p class="jiantou">
              领导寄语
              <img src="../assets/jiantou.png" alt="">
            </p>
            <div class="fuwenben" v-html="aboutUs.leaderMessage"></div>
<!--            <p>{{aboutUs.leaderMessage}}</p>-->
          </div>
        </div>
        <p class="lingdaop">企业文化</p>
        <div class="toptwo">
          <div class="toptworight">
            <p class="jiantou">
              企业文化
              <img src="../assets/jiantou.png" alt="">
            </p>
            <div class="fuwenben" v-html="aboutUs.corporateCulture"></div>
<!--            <p>{{aboutUs.corporateCulture}}</p>-->
          </div>
          <img :src="aboutUs.corporateCulturePic" alt="">
        </div>
        <p class="lingdaop">荣誉资质</p>
        <div class="topthree">
          <div class="rongyuimg" v-for="(item,index) in aboutall.honorsList">
            <el-image
                :src="item.pic"
                fit="contain"></el-image>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
const axios = require('axios')
export default {
    data() {
        return {
            url:'',
            banner: "",
            list: [],
            honorList: [],
            html: '',
            show: true,
            Show: false,
            color: 'companyIntro',
            title: '公司简介',
            companyShow: true,
            leaderShow: false,
            CultureShow: false,
            aboutall: {},
          aboutUs: {},
        }
    },
    created() {
        this.getData()
        // if (this.$route.query.value) {
        //     this.to(this.$route.query.value)
        // }
    },
    watch: {
        $route(to, from) {
            if (this.$route.query.value) {
                let value = String(this.$route.query.value)
                this.to(value);
            }
        }
    },
    methods: {
        to(val) {
            // this.color = val
            // this.Show = false
            // this.show = true
            // if (val == 'companyIntro') {
            //     this.companyShow = true;
            //     this.leaderShow = false;
            //     this.CultureShow = false;
            //     this.title = '公司简介'
            // } else if (val == 'leaderMessage') {
            //     this.companyShow = false;
            //     this.leaderShow = true;
            //     this.CultureShow = false;
            //     this.title = '领导寄语'
            // } else if (val == 'corporateCulture') {
            //     this.companyShow = false;
            //     this.leaderShow = false;
            //     this.CultureShow = true;
            //     this.title = '企业文化'
            // } else {
            //     this.title = '荣誉资质'
            //     this.goHonor(val)
            // }
        },
        getData() {
            this.$http({
                url: 'aboutUs/queryAboutUsInfo',
                method: 'post'
            }).then((res) => {
              this.aboutall = res.data
              this.aboutUs=res.data.aboutUs
                this.banner = res.data.banner
            })
        },
        goHonor(val) {
            this.title = '荣誉资质'
            this.color = val
            this.Show = true
            this.show = false
            this.$http({
                url: 'aboutUs/queryHonors?page=1&limit=10',
                method: "post"
            }).then((res) => {
                this.honorList = res.data
            })
        }
    },
}
</script>


<style scoped lang="scss">
.jiantou{
  position: relative;
img{
  width: 13px !important;
  height: 13px !important;
  position: absolute;
  left: -13px;
  margin-top: 0 !important;
  top: -6px;
}
}
.topthree{
  width: 100%;
  height: auto;
  //display: flex;
  //justify-content:flex-start;

  .rongyuimg{
    float: left;
    width: 19%;
    height: 315px;
    margin-left: 1.25%;
    box-sizing: border-box;
    padding: 18px 24px;
    background: white;
    border: 2px solid #F3F3F8;
    box-shadow: 0px 1px 24px 0px rgba(47,55,72,0.07);
    margin-bottom: 45px;
    .el-image{
      width: 100%;
      height: 100%;
    }
  }
  .rongyuimg:nth-child(5n+1){
    margin-left: 0 !important;
  }
}
.toptwo{
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 43px 35px 51px 35px;
  background: #131c2d;
  display: flex;
  justify-content:space-between;
  img{
    width: 45%;
    height: 400px;
    margin-top: -74px;
  }
  .toptworight{
    color: white;
    width: 47%;
    p:nth-child(1){
      font-size: 24px;
      color: white;
      margin-bottom: 32px;
    }
    .fuwenben{
      font-size: 18px;
      white-space: pre-line;
      //text-indent:2em;
    }
    p:nth-child(2){
      font-size: 18px;
      text-indent:2em;
    }
  }
}
.topone{
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 63px;
  font-size: 18px;
  color: #8D8D8D;
  background: white;
  box-shadow: 0px 7px 35px 0px rgba(41,41,41,0.32);
  p:nth-child(1){
    font-size: 32px;
    color: #2F2F2F;
    margin-bottom: 16px;
  }
  p:nth-child(2){
    font-size: 20px;
    color: #A1A1A1;
    margin-bottom: 34px;
  }
  p:nth-child(3){
    font-size: 18px;
    color: #8D8D8D;
  }
}
.newcontecttop{
  width: 100%;
  height: 400px;
  background: #f6f7fb;
  text-align: center;
  img{
    width: 408px;
    height: auto;
    margin-top: 92px;
  }
}
.newcontect{
  width: 62%;
  min-height: 200px;
  margin: auto;
  margin-top: -200px;
  p{
    margin: 0;
  }
  .lingdaop{
    margin: 88px 0 83px 0;
    font-size: 32px;
    color: #2F2F2F;
    font-weight: bold;
    text-align: center;
  }
}
//*****************
.cultureName {
    font-size: 36px;
    font-family: Alibaba PuHuiTi;
}

.cultureContent {
    font-size: 16px;
}

.companyIntroSynopsis {
    margin-top: 30px;
    color: #4c5462;
    font-size: 16px;
}

.cultureLine {
    position: absolute;
    right: 0;
}

.cultureContentRight {
    right: 40px;
    width: 100%;
    text-align: right;
    color: #fff;
    font-size: 24px;
    margin-top: 40px;
    position: absolute;
}

.cultureContentLeft {
    margin-top: 40px;
    margin-left: 40px;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 24px;
    position: absolute;
}

.scale {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: all 0.6s ease;
}

.card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.corporateCultureList {
    width: 1159px;
    height: 360px;
    overflow: hidden;
    margin-top: 20px;
    // position: relative;

}

.corporateCultureList img {
    width: 1159px;
    height: 360px;
}

.corporateCultureList:hover .scale {
    transform: scale(1.1);
}

.honorTitle {
    height: 30px;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #4C5462;
}

.company {
    margin-left: 20px;
}

.companyName {
    font-size: 24px;
    font-weight: 600;
    color: #4C5462;
}

.h1 {
    margin-top: 30px;
    height: 30px;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #4C5462;

}

.companyIntroLine {
    width: 100%;
    height: 1px;
    background-color: #EBEEF3;
    margin-top: 20px;
    margin-bottom: 20px;
}

.honorListDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    justify-content: flex-start;

}

.nochoose {
    background-color: #1C3B89;
    color: #4C5462;
}

.optionText {
    margin-left: 5%;
    width: 90%;
    margin-right: 5%;
}



.honorList {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 100px;

    .applicationCasesDiv {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .name {
        margin-top: 6px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #4C5462;
    }

    img {
        margin-top: 10px;
        width: 389px;
        height: 256px;
        border: 5px solid #FFFFFF;
    }

    .applicationCasesDiv img:hover {
        border: 5px solid gray;
    }
}

.choose {
    margin-top: 10px;
    width: 280px;
    height: 56px;
    background: #EBEEF3;
    border-radius: 4px;
    line-height: 56px;
    text-align: center;

}

.choose span {
    margin-left: 15px;
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #4C5462;
}

.titleImg {
    background: url(../assets/image/dianchuang.png);
    width: 280px;
    height: 97px;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 97px;
    text-align: center;

}

.page {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 730px;
}

.contect {
    width: 85%;
    margin-left: 15%;
    margin-bottom: 50px;
}

.flex {
    display: flex;
}

.title {
    height: 30px;
    line-height: 30px;
    color: #A0A8B5;
    font-size: 18px;
    margin: 15px 0;
}

.line {
    width: 80%;
    height: 1px;
    background-color: #EBEEF3;
}
</style>
