<template>
  <div style="margin-bottom: 150px; " class="body">
    <template v-if="detailslook">
      <div class="page" :style="{ backgroundImage: 'url(' + banner + ')' }"></div>
      <div>
        <div class="contect">
          <div class="title">当前位置：建筑智能化>{{ title }}</div>
          <div class="line"></div>
          <div class="flex introduce">
            <!--                    <div class="options">-->
            <!--                        <div style="padding-top: 20px;padding-right: 20px;">-->
            <!--                            <div class="h3">产品服务</div>-->
            <!--                            <div class="h3Line"></div>-->
            <!--                            <div v-for="(item, index) in productServiceDtoList" :key="'index1' + index">-->
            <!--                                <div style="margin-top:20px; width: 50%;" @click="goComponent(item.id)"-->
            <!--                                    :style="{borderBottom:(id == item.id ? '1px solid grey' : 'none')}" class="name">{{-->
            <!--                                    item.name }}</div>-->
            <!--                            </div>-->
            <!--                        </div>-->

            <!--                        <div style="padding-bottom:20px ; margin-top: 20px;">-->
            <!--                            <div class="h3" @click="goCase">应用案例</div>-->
            <!--                            <div class="h3Line"></div>-->
            <!--                        </div>-->

            <!--                    </div>-->
            <div class="options">
              <div class="chanpintext">产品服务</div>
              <div @click="goComponent(item.id)" v-for="item in productServiceDtoList"
                   :class="[id == item.id ? 'grenying' : '','newname']">
                <img class="uiconw" :src="id == item.id ?item.checkedIcon:item.noCheckedIcon" alt="">
                {{ item.name }}
                <br/>
              </div>
              <div style="padding-bottom: 30px; white-space: nowrap; margin-top: 20px;">
                <div class="chanpintext" @click="goCasetwo">
                  应用案例
                  <template v-if="yingtoal>0">（{{ yingtoal }}）</template>
                </div>
              </div>
            </div>
            <div class="optionText" v-if="Show">
              <div class="title">{{ introduce.name }}</div>
              <div class="line2"></div>
              <div v-html="introduce.introduce" class="introduceHtml"></div>
            </div>
            <div class="optionText" v-else>
              <div class="title">
                <span>应用案例</span>
                <div class="biaoqian">
                  <span @click="qiecolor(99,'全部')" :class="[qiecolort == 99 ?'bluecolor':'']">全部</span>
                  <span @click="qiecolor(index,item.name)" :class="[qiecolort == index ?'bluecolor':'']"
                        v-for="(item,index) in productServiceDtoList" :key="item.id">{{ item.name }}</span>
                </div>
              </div>
              <!--                        <div class="line3"></div>-->
              <div class="flexDiv">
                <!--                            <div class="applicationCasesList" v-for="item in applicationCasesList">-->
                <!--                                <div class="applicationCasesDiv">-->
                <!--                                    <img :src="item.pic" alt="">-->
                <!--                                    <div class="span">{{item.name}}</div>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <template v-for="item in applicationCasesList">
                  <div class="applicationCasesDiv" @click="yinyong(item.id)">
                    <img :src="item.pic" alt="">
                    <div class="detailbomtext">
                      {{ item.name }}
                      <div>
                        <span class="bomspan">{{ item.productServiceName }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="!detailslook">
      <div class="detailbottom">
        <div class="titletop nowhei">当前位置：建筑智能化>{{ title }} <span @click="backlist" class="backlist">返回</span></div>
        <div class="detailrigh" v-html="detailsin"></div>
      </div>
    </template>
    </div>
</template>





<script>
// import swiper from '../components/swiper'
import 'swiper/css/swiper.min.css';
// import 'swiper/css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
const axios = require("axios");
export default {
  name: 'building',
  // components:{
  //     swiper
  // },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      galleryThumbs: {},
      newList: [],
      banner: '',
      productServiceDtoList: [],
      title: '产品服务',
      id: '',
      introduce: '',
      Show: true,
      productList: [],
      applicationCasesList: [],
      shaiying: [],
      show: true,
      index: '',
      imgUrl: '',
      yingtoal: 0,
      qiecolort: 99,
      detailsin:'',
      detailslook:true,
      querychild:'',
    }
  },

  created() {
    this.getData()
    this.goCase()
    this.querychild = this.$parent.parentid
    if(this.$route.query.id){
      this.goComponent(this.$route.query.id)
    }

  },
  mounted() {
  },
  watch: {
    detailslook(){
      // console.log('更改值',this.detailslook)
      this.goComponent(this.querychild);
    },
    $route(to, from) {
      if (this.$route.query.id) {
        this.goComponent(this.$route.query.id);
      }else{
        this.goComponent(this.querychild);
      }
    },
    productServiceDtoList(){
      this.goComponent(this.querychild);

    },
    querychild(){
      this.goComponent(this.querychild);

    },
  },
  methods: {
    onSwiper() {

    },
    onSlideChange() {

    },
    backlist(){
      this.detailslook = true
      this.$emit("getUser",true)
    },

    yinyong(val){
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.detailslook = false
      this.$emit("getUser",false)
      this.$http({
        url: 'productApi/queryApplicationCasesDetail?applicationCasesId='+ val +'',
        method: 'post',
      }).then((res) => {
        this.detailsin = res.data.introduce
      })
      // this.$router.push({
      //   path: '/details',
      //   query: {
      //     id: val
      //   }
      // })
    },
    qiecolor(val, name) {
      this.qiecolort = val
      if (name == '全部') {
        this.applicationCasesList = this.shaiying
      } else {
        let arr = this.shaiying.filter(item => {
          return item.productServiceName == name
        })
        this.applicationCasesList = arr
      }
    },
    getData() {
      this.$http({
        url: 'productApi/queryProductService?type=3',
        method: 'post'
      }).then((res) => {
        this.banner = res.data.banner
        this.productServiceDtoList = res.data.productServiceList
        if (this.productServiceDtoList&&this.productServiceDtoList.length > 0 && this.querychild == '') {
          this.goComponent(this.productServiceDtoList[0].id)
        }
        // for (let i = 0; i < this.productServiceDtoList.length; i++) {
        //     this.introduce = this.productServiceDtoList[0]
        //     this.imgUrl = this.introduce.picUrlList[0].fileUrl
        //     this.id = !this.id ? this.productServiceDtoList[0].id : this.id
        //     this.goComponent(this.productServiceDtoList[0].id)
        // }
      })

    },
    goCasetwo() {
      this.title = '应用案例'
      this.show = false
      this.Show = false
      this.id = ''
      this.qiecolor(99,'全部')

    },
    goCase() {
      this.$http({
        url: 'productApi/queryApplicationCases?page=1&limit=10&type=3',
        method: 'post'
      }).then((res) => {
        this.applicationCasesList = res.data
        this.shaiying = res.data
        this.yingtoal = res.count

      })

      // this.$http({
      //     url: 'productApi/queryApplicationCasesDetail?type=3&applicationCasesId=' + val,
      //     method: 'post',
      // }).then((res) => {
      //     this.applicationCasesList = res.data
      // })

    },
    goComponent(id) {
      this.id = id
      this.title = '产品服务'
      this.show = true
      this.Show = true
      for (let i = 0; i < this.productServiceDtoList.length; i++) {
        if (id == this.productServiceDtoList[i].id) {
          this.introduce = this.productServiceDtoList[i]?this.productServiceDtoList[i]:this.productServiceDtoList[0]
          // console.log(this.introduce);
        }
      }
      this.id=this.id==''?this.productServiceDtoList[0].id:this.id

    },
    // 底部过来的值
    goName(val){
      this.title = '产品服务'
      this.show = true
      this.Show = true
      for (let i = 0; i < this.productServiceDtoList.length; i++) {
        if (val == this.productServiceDtoList[i].name) {
          this.introduce = this.productServiceDtoList[i]
          this.id = this.productServiceDtoList[i].id
        }
      }
    },
  },
}
</script>



<style scoped lang="scss">
//666
.bomspan{
  box-sizing: border-box;
  background: #F5F5FB;
  border-radius: 2px;
  color: #64728A;
  font-size: 14px;
  padding: 7px;
  margin-top: 15px;
  display: inline-block;
}
.detailbomtext{
  width: 100%;
  box-sizing: border-box;
  padding: 14px 13px;
  font-size: 18px;
  color: #4C5462;
}
.applicationCasesDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  margin-left: 3.3%;
  height: auto;
  border-radius: 10px;
  border: 1px solid #E9EDF1;
  margin-top: 40px;
  cursor: pointer;
  //transform:scale(1);
  transition: all 0.3s;
  img{
    width: 100%;
    height: 220px;
    border-radius: 10px 10px 0 0;
  }
}
.applicationCasesDiv:hover{
  transform:scale(1.1);
  -webkit-box-shadow: #ccc 0px 10px 10px;
  -moz-box-shadow: #ccc 0px 10px 10px;
  box-shadow: #ccc 0px 10px 10px;
  transition: all 0.3s;

}

.applicationCasesDiv:nth-child(3n+1){
  margin-left: 0 !important;
}
.grenying{
  background: linear-gradient(90deg, #08215A, #092D81) !important;
  border: 1px solid #092D81;
  color: white !important;
}
.newname{
  width: 100%;
  height: 55px;
  line-height: 55px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  margin-top: 12px;
  box-sizing: border-box;
  padding: 0 16px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  .uiconw{
    width: 30px !important;
    height: auto;
    vertical-align: middle;
    margin-right: 17px;
  }
}
.chanpintext{
  margin-bottom: 10px;
  color: #08215A !important;
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
}
.backlist{
  float: right;
  font-size: 14px;
  cursor: pointer;
}
.detailrigh{
  box-sizing: border-box;
  /deep/ img{
    max-width: 100% !important;
  }
  //padding-left: 120px;
}
.detailbottom{
  width: 60%;
  min-height: 800px;
  //background: pink;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 120px;
}
.bluecolor{
  background: #F1F5FF;
  border: 1px solid #3E77FF !important;
}
.biaoqian{
  display: inline-block;
  vertical-align: 3px;
  span{
    display: inline-block;
    width: 100px;
    height: 30px;
    box-sizing: border-box;
    padding: 0 5px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #D2D3E4;
    border-radius: 6px;
    font-size: 14px;
    margin-left: 15px;
    cursor: pointer;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    float: left;
    margin-bottom: 5px;
  }
  span:hover{
    background: #F1F5FF;
    border: 1px solid #3E77FF;
    transition:0.3s;
  }
  span:nth-child(1){
    //margin-left: 24px;
  }
}
.titletop {
  height: 30px;
  line-height: 30px;
  color: #A0A8B5;
  font-size: 14px;
  margin: 15px 0;
}
.nowhei{
  margin: 40px 0 40px -120px;
}
//********
html,
body {
    width: 100%;
}

.body {
    max-width: 100%;
    overflow: hidden;
}
.display{
    display: none!important;
    
    
}

// .swiper-button-next {
//     top: 110px;
//     position: absolute;
//     color: #ffffff;
//     width: 26px;
//     height: 100px;
//     border-radius: 2px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

// }

// .swiper-button-prev {
//     top: 110px;
//     position: absolute;
//     color: #ffffff;
//     width: 26px;
//     height: 100px;
//     border-radius: 2px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
.swiper-button-prev:after{
    font-size: 16px!important;
}
.swiper-button-next:after{
    font-size: 16px!important;
}
.introduceHtml{
    margin-top: 50px;
    width: 85%;
  /deep/ img {
    max-width: 100% !important;
  }
}

.thumb-example {
    height: 480px;
    background-color: #000;
}

.swiperBottom {
    width: 100% !important;
}

.swiper:hover{
    .display{
        display: block!important;
    }
    .right{
        margin-right: -20px;
        // margin-top: 1px;
    }
}

.swiper {

    .swiper-slide {
        background-size: cover;
        background-position: center;

        &.slide {
            width: 102px;
            height: 68px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &.slideTop {
            width: 350px;
            height: 250px;

            img {
                width: 100%;
                height: 100%;
            }
        }

    }

    &.gallery-top {
        height: 80%;
        width: 100%;
    }

    &.gallery-thumbs {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
    }

    &.gallery-thumbs .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
    }

    &.gallery-thumbs .swiper-slide-active {
        opacity: 1;
    }
}










.rightTitle {
    // width: 20%;
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #ABABAB;
    line-height: 24px;
    white-space: nowrap;
}

.applicationCasesList {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    .applicationCasesDiv {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
    img {
        width: 389px !important;
        height: 240px;
        border: 5px solid #FFFFFF;

    }

    .span {
        margin-top: 6px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #4C5462;
    }
    .applicationCasesDiv img:hover{
        border: 5px solid gray;
    }

}

.h3 {
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: 700;
    color: #4C5462;
}

.h3Line {
    margin-top: -8px;
    height: 8px;
    width: 30px;
    background-color: #FFBA00;
}

.flexDiv {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    //justify-content: space-between;

}

.introduceContect {
    margin-left: 100px;
    display: flex;
    justify-content: flex-start;
    width: 33.33%;
    justify-content: center;
    align-items: center;


    .name {
        font-size: 22px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 24px;
    }

    .line {
        width: 26px;
        height: 4px;
        background-color: #ffffff;
    }

    .text {
        font-size: 16px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        display: none;
    }
}

.introduceContect:hover {
    justify-content: flex-start;

    .name {
        margin-top: 50px !important;
    }

    .icon {
        display: none;
    }

    .text {
        display: block;
    }
}

.Image {
    margin-top: 50px;
    width: 100%;
    justify-content: space-between;
    margin-left: -100px;


    .line {
        width: 26px;
        margin-bottom: 10px;
    }

    .name {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.image {
    margin-top: 50px;
    width: 80%;
}

.titleText {
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #4C5462;
    line-height: 24px;
    margin-left: 20px;
}

.optionTitle {
    white-space: nowrap;
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #ABABAB;
    line-height: 24px;
}

.page {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
}

.contect {
    width: 85%;
    margin-left: 15%;
}

.flex {
    display: flex;
}

.title {
    height: 30px;
    line-height: 30px;
    color: #A0A8B5;
    font-size: 14px;
    margin: 15px 0;
}

.line {
    width: 80%;
    height: 1px;
    background-color: #EBEEF3;
}

.introduce {
    margin-top: 30px;
    display: flex;

    .leftArrow {
        height: 40px;
        line-height: 40px;
    }

    .rightArrow {
        height: 40px;
        line-height: 40px;
    }

    .imgUrl {
        width: 320px;
        height: 200px;
    }

    .image {
        margin-top: 0 !important;
        display: flex;

        img {
            width: 60px;
            height: 40px;
            margin-left: 5px;
        }
    }

    .synopsis {
        margin-left: 10px;
        font-size: 18px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #4C5462;
        line-height: 24px;
    }
}


.options {
    height: auto;
    width: 280px!important;
    //background: #F5F6FA;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    .Line {
        width: 23%;
        height: 1px;
        background-color: #4C5462;
    }

    .h3 {
        margin-left: 20px;
    }

    .h3Line {
        margin-left: 20px;
    }

    .name {
        line-height: 40px;
        margin-left: 30px;
        font-size: 14px;
        display: inline-block;
        display: inline;
        width: auto;
        height: auto;
    }
}



.optionText {
    margin-left: 5%;
    width: 90%;

    .Image {
        margin-top: 50px;
        width: 100%;
        justify-content: space-between;
        margin-left: -100px;


        .line {
            width: 26px;
            margin-bottom: 10px;
        }

        .name {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .title {
        width: 80%;
        height:auto;
        font-size: 30px;
        font-family: Alibaba PuHuiTi;
      font-weight: bold;
        //font-weight: 500;
        color: #4C5462;
        white-space: nowrap;
      span:nth-child(1){
        float: left;
      }
    }


}

.line2 {
    width: 89%;
    height: 1px;
    background-color: #EBEEF3;
}
.line3{
    width: 91%;
    height: 1px;
    background-color: #EBEEF3;
}
</style>