import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({ showSpinner: false });// NProgress configuration

router.beforeEach(async(to, from, next) => {
  NProgress.start();
  next()
});


router.afterEach((to, from) => {
  NProgress.done() // 结束Progress
})
