

<template>
  <div class="page" ref="outhome">
    <div class="navigation" :style="{background:bgcolor,width:outwidth}">
      <div class="navigationImg">
        <img :src="logo" class="logo">
        <span class="xuzhou">[&nbsp;徐州&nbsp;]</span>
      </div>
      <div class="flex navigationText">
        <!--        <div class="shouyeheder" @click="jumpTo('/')">-->
        <!--          首页-->
        <!--        <div v-if="value == '/'" class="dibucss"></div>-->
        <!--        </div>-->
        <!--        <div @click="jumpTo('government')">-->
        <!--          <el-dropdown v-if="mapList.length > 0" placement='top'>-->
        <!--            <span class="el-dropdown-link">-->
        <!--              <template v-if="mapList.length > 0">-->
        <!--                {{mapList[1].title}}-->
        <!--              </template>-->
        <!--              <div v-if="value == '/government'" class="dibucss"></div>-->
        <!--            </span>-->
        <!--            <el-dropdown-menu slot="dropdown" v-if="mapList[1].childTitle.length > 0">-->
        <!--              <el-dropdown-item v-for="item in mapList[1].childTitle" :key="item.id" @click.native="governmentCommand(item.id)">{{item.name}}</el-dropdown-item>-->
        <!--            </el-dropdown-menu>-->
        <!--          </el-dropdown>-->
        <!--        </div>-->
        <!--        <div @click="jumpTo('industrial')">-->
        <!--          <el-dropdown v-if="mapList.length != 0" placement='top'>-->
        <!--            <span class="el-dropdown-link">-->
        <!--              <template v-if="mapList.length > 0">-->
        <!--                {{mapList[2].title}}-->
        <!--              </template>-->
        <!--              <div v-if="value == '/industrial'" class="dibucss"></div>-->
        <!--            </span>-->
        <!--            <el-dropdown-menu slot="dropdown" v-if="mapList[2].childTitle.length > 0">-->
        <!--              <el-dropdown-item   v-for="item in mapList[2].childTitle" :key="item.id" @click.native="industrialCommand(item.id)">{{item.name}}</el-dropdown-item>-->
        <!--            </el-dropdown-menu>-->
        <!--          </el-dropdown>-->
        <!--        </div>-->
        <!--        <div @click="jumpTo('building')">-->
        <!--          <el-dropdown v-if="mapList.length != 0" placement='top'>-->
        <!--            <span class="el-dropdown-link">-->
        <!--              <template v-if="mapList.length > 0">-->
        <!--                {{mapList[3].title}}-->
        <!--              </template>-->
        <!--              <div v-if="value == '/building'" class="dibucss"></div>-->
        <!--            </span>-->
        <!--            <el-dropdown-menu slot="dropdown" v-if="mapList[3].childTitle.length > 0">-->
        <!--              <el-dropdown-item   v-for="item in mapList[3].childTitle" :key="item.id" @click.native="buildingCommand(item.id)">{{item.name}}</el-dropdown-item>-->
        <!--            </el-dropdown-menu>-->
        <!--          </el-dropdown>-->
        <!--        </div>-->
        <!--        <div>-->
        <!--          <el-dropdown @command="handleCommand" placement='top'>-->
        <!--            <span @click="jumpTo('about')" class="el-dropdown-link">-->
        <!--              <template v-if="mapList.length > 0">-->
        <!--                {{mapList[4].title}}-->
        <!--              </template>-->
        <!--              <div v-if="value == '/about'" class="dibucss"></div>-->
        <!--            </span>-->
        <!--            <el-dropdown-menu slot="dropdown" v-if="mapList[4].title">-->
        <!--&lt;!&ndash;              <el-dropdown-item command="companyIntro">公司简介</el-dropdown-item>&ndash;&gt;-->
        <!--&lt;!&ndash;              <el-dropdown-item command="leaderMessage">领导寄语</el-dropdown-item>&ndash;&gt;-->
        <!--&lt;!&ndash;              <el-dropdown-item command="goHonor">荣誉资质</el-dropdown-item>&ndash;&gt;-->
        <!--&lt;!&ndash;              <el-dropdown-item command="corporateCulture">企业文化</el-dropdown-item>&ndash;&gt;-->
        <!--&lt;!&ndash;              <el-dropdown-item   v-for="item in mapList[4].childTitle" :key="item.id" @click.native="handleCommand(item.id)">{{item.name}}</el-dropdown-item>&ndash;&gt;-->

        <!--            </el-dropdown-menu>-->
        <!--          </el-dropdown>-->
        <!--        </div>-->
        <!--        <div @click="jumpTo('contact')">-->
        <!--          <template v-if="mapList.length > 0">-->
        <!--            {{mapList[5].title}}-->
        <!--          </template>-->
        <!--          <div v-if="value == '/contact'" class="dibucss"></div>-->
        <!--        </div>-->


        <div class="overhidden" v-for="(item,index) in mapList" :key="index" @click="newjumpTo(index)">
          <el-dropdown placement='top' :tabindex="item.index">
            <span class="el-dropdown-link">
              <template>
                {{item.title}}
              </template>
              <div v-if="colorindex == index" class="dibucss"></div>
            </span>
            <el-dropdown-menu style="top: 121px !important;" class="tophang" slot="dropdown" v-if="item.childTitle.length > 0">
              <el-dropdown-item   v-for="(itemtwo,indextwo) in item.childTitle" :key="itemtwo.id" @click.native="handleCommand(itemtwo.id,index)">{{itemtwo.name}}</el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu>
              <el-dropdown-item></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
<!--    悬浮窗-->
    <div class="xuanfu">
      <div v-for="(item,index) in xuanfulist" @click="gotop(index)" @mouseenter="mouseover(index)" @mouseleave="mouseLeave(index)"
           :key="index"
           :class="[xuanindex ==index?'greenxuan':'', 'hoverone']">
        <el-image
            :src="xuanindex ==index?item.url2:item.url1"
            fit="contain"></el-image>
      </div>
      <div class="weiab" v-if="xuanindex == 0">
<!--      <div class="weiab">-->
        <p>联系微信</p>
        <img src="../assets/weixin.png" alt="">
      </div>
      <div class="phoneab"  v-if="xuanindex == 1">
        <span>联系电话：</span>
        <span>15605206608</span>
      </div>
    </div>
<!--    路由位置-->
      <router-view @getUser="getUser" ref="childData" :key="$route.fullPath"></router-view>

    <div class="foot flex">
      <div class="left flex">
        <div class="leftText updownauto">
          <img :src="allmes.bottomLogo" alt="">
          <div class="zixunphone">
            <p>咨询电话</p>
            <p>{{ allmes.hotLine }} </p>
<!--            <p>15605206608</p>-->
          </div>
        </div>
        <div class="btn updownauto">
          <div class="business flex" @click="goCallme">
            <img src="../assets/bomone.png" alt="">
            <span>合作咨询</span>
          </div>
          <div class="business flex" @click="toAbout">
            <img src="../assets/bomtwo.png" alt="">
            <span>主营业务</span>
          </div>
        </div>
      </div>
      <div class="right flex">
        <div class="rightContect">
          <div class="contecttext flex">
            <div class="text" v-for="(item,index) in mapList" v-if="index == 1 || index == 2 || index == 3 || index == 4" :key="index">
              <p> {{item.title}}</p>
              <p v-for="(itemtwo,indextwo) in item.childTitle" @click="handleCommand(itemtwo.id,index)" v-if="indextwo < 4" :key="itemtwo.id">{{itemtwo.name}}</p>
            </div>


<!--            <div class="text">-->
<!--              <p>数字政府</p>-->
<!--              <p @click="jumpTo('government')" class="to">数字政府</p>-->
<!--              <p @click="jumpTo('industrial')" class="to">数字工业</p>-->
<!--              <p @click="jumpTo('building')" class="to">建筑智能化</p>-->
<!--            </div>-->
<!--            <div class="text">-->
<!--              <p>数字工业</p>-->
<!--              <p @click="valueTo('industrial','ERP企业管理系统')" class="to">ERP企业管理系统</p>-->
<!--              <p @click="valueTo('industrial','MES制造执行系统')" class="to">MES制造执行系统</p>-->
<!--              <p @click="valueTo('industrial','数据采集与监视控制系统')" class="to">数据采集与监视控制系统</p>-->
<!--              <p @click="valueTo('industrial','数字孪生')" class="to">数字孪生</p>-->
<!--            </div>-->
<!--            <div class="text">-->
<!--              <p>建筑智能化</p>-->
<!--              <p @click="valueTo('building','建筑设备自动化系统')" class="to">建筑设备自动化系统</p>-->
<!--              <p @click="valueTo('building','通信自动化系统')" class="to">通信自动化系统</p>-->
<!--              <p @click="valueTo('building','楼宇自控系统')" class="to">楼宇自控系统</p>-->
<!--              <p @click="valueTo('building','安全防范自动化系统')" class="to">安全防范自动化系统</p>-->
<!--            </div>-->
<!--            <div class="text">-->
<!--              <p>关于点创</p>-->
<!--              <p @click="valueTo('about','companyIntro')" class="to">公司简介</p>-->
<!--              <p @click="valueTo('about','leaderMessage')" class="to">领导寄语</p>-->
<!--              <p @click="valueTo('about','goHonor')" class="to">荣誉资质</p>-->
<!--              <p @click="valueTo('about','corporateCulture')" class="to">企业文化</p>-->
<!--            </div>-->
          </div>
        </div>

      </div>
    </div>
    <div class="bottom">
      <span><a class="beiana" target="_blank" href="https://beian.miit.gov.cn/">{{allmes.recordNo}}</a> {{allmes.copyright}}</span>
    </div>
  </div>
</template>

<script>
// //防止页面后退  使用在vue时 挂载到mounted中
// history.pushState(null, null, document.URL);
// window.addEventListener('popstate', function () {
//   history.pushState(null, null, document.URL);
// });
const axios = require("axios")
export default {
  data() {
    return {
      xuanindex:99,
      xuanfulist:[{url1:require('../assets/erwei1.png'),url2:require('../assets/erwei2.png')},{url1:require('../assets/shouji1.png'),url2:require('../assets/shouji2.png')},{url1:require('../assets/up.png'),url2:require('../assets/up.png')}],
      colorindex:0,
      value: '/',
      logo:'',
      picIndex:'',
      bottomList:[],
      mapList:'',
      bottomLogo:'',
      contactsList:'',
      allmes:'',
      pushadress:'',
      bgcolor:'rgba(0, 0, 0, 0.1)',
      outwidth:'100%',
      parentid:'',

    }

  },

  watch: {
    '$route': 'getPath'
  },
  mounted() {
    this.getDate()
    this.outwidth = this.$refs.outhome.offsetWidth + 'px'
    window.onresize = () => {
      this.outwidth = this.$refs.outhome.offsetWidth + 'px'
    }
  },
  created() {
    // this.$router.push({
    //   path: "/"
    // })
    this.parentid=''
    this.getPath()
    window.addEventListener("load", () => {
      this.colorindex = 99
      this.getPath()
    });
  },
  methods: {
    getUser(val){
      if(val){
        this.bgcolor='rgba(0, 0, 0, 0.1)'
      }else{
        this.bgcolor='#000940 !important'
      }
    },
    gotop(index){
      if(index == 2){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    },
    mouseover(val){
      this.xuanindex = val
    },
    mouseLeave(val){
      this.xuanindex = 99
    },
    getDate(){
      this.$http({
        url:'indexApi/queryIndexInfo',
        method:'post',
      }).then((res)=>{
        this.logo = res.data.logo
        this.mapList = res.data.mapList
        this.allmes = res.data
      })

      // this.$http({
      //   url:'footApi/queryFootInfo',
      //   method:'post',
      // }).then((res)=>{
      //   this.bottomList = res.data
      //   this.bottomLogo = res.data.bottomLogo
      //   this.contactsList = res.data.contactsList
      // })

    },
    goCallme(){
      this.$router.push({
        path:'contact'
      })
    },
    toAbout(){
      this.$router.push({
        path:'government'
      })
    },
    handleCommand(id,val) {
      this.parentid=id
      this.getUser(true)
      this.colorindex = val
      if(val == 4){
        // this.newhandleCommand(id)
        this.valueTo('about', '')
      }else{
        this.newjumpTo(val)
        // this.$router.push({
        //   path: this.newjumpTo(val),
        //   query:{id:id}
        // })
      }
    },
    newjumpTo(val){
      // console.log('点击跳转',this.$refs.childData)
      this.$refs.childData.detailslook = true
      this.getUser(true)
      this.colorindex = val
      let data = ''
      switch (val){
        case 0:
          data = '/'
          break;
        case 1:
          data = '/government'
          break;
        case 2:
          data = '/industrial'
          break;
        case 3:
          data = '/building'
          break;
        case 4:
          data = '/about'
          break;
        case 5:
          data = '/contact'
          break;
      }
      this.pushadress = data
      console.log('this.parentid',this.parentid,data)
      this.$router.push({
        path: data,
        query:{id:this.parentid}
      })
      return data
    },
    newhandleCommand(command) {
      let data= ''
      if(command == 1){
        data = 'companyIntro'
      }else if(command == 2){
        data = 'leaderMessage'
      }else if(command == 3){
        data = 'goHonor'
      }else{
        data = 'corporateCulture'
      }
      this.valueTo('about', data)
    },

    getPath(v1, v2) {

      this.value = this.$route.path
      // console.log('this.value-',this.value ,'this.$route.path',this.$route.path)
      this.$router.push({
        path: this.value == '/'?'/':this.value
      })
      let findex = 0
      switch (this.$route.path){
        case '/':
          this.colorindex = 0
          break;
        case '/government':
          this.colorindex = 1
          break;
        case '/industrial':
          this.colorindex = 2
          break;
        case '/building':
          this.colorindex = 3
          break;
        case '/about':
          this.colorindex = 4
          break;
        case '/contact':
          this.colorindex = 5
          break;
        default:
          this.colorindex = this.colorindex
          break;
      }
    },
    jumpTo(val) {
      this.getUser(true)
      this.$router.push({
        path: val
      })
    },

    valueTo(val,value){
      this.getUser(true)
      this.$router.push({
        path:val,
        query:{
          value:value
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.beiana{
  color: #525559;
  font-size: 16px;
  text-decoration-line: none;
}
.overhidden{
  overflow: hidden;
}
//.el-dropdown-menu{
//  margin-top: -30px!important;
//}
.el-dropdown-menu{
  margin-top: -85px !important;
}
.weiab{
  width: 160px;
  height: 169px;
  background: white;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -168px;
  top: 0;
  p{margin-bottom: 13px;}
  img{
    width: 95px;
    height: 95px;
  }
}
.phoneab{
  width: 235px;
  height: 80px;
  position: absolute;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -243px;
  top: 80px;
  span:nth-child(1){
    font-size: 14px;
    color: #9AA8C1;
    z-index: 100;
  }
  span:nth-child(2){
    font-size: 18px;
    color: #3E77FF;
    z-index: 100;
  }
}
.greenxuan{
  background: #3E77FF !important;
  transition: all 0.3s;
}
.hoverone{
  width: 80px;
  height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
.el-image{
  width: 36px;
}
}
.xuanfu{
  width: 80px;
  height: 240px;
  background: #ffffff;
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 99;
}
.navigationImg {
  margin: auto 0;
  margin-left: 50px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
}
.logo{
  width: 140px;
  max-height: 90px;
}
p{margin: 0;}
.zixunphone{
  p:nth-child(1){
    margin-top: 30px;
    font-size: 14px;
    margin-bottom: 10px;
    color: #5D6169;
  }
  p:nth-child(2){
    font-size: 24px;
    color: white;
  }
}
.xuzhou{
  position: absolute;
  right: -90px;
  bottom: 0px;
  color: white;
  font-size: 20px;
}

.el-dropdown-link {
  font-size: 18px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.bottom {
  width: 100%;
  height: 80px;
  background-color: #1A1B1D;
  line-height: 80px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #525559;
  text-align: center;
}

.bottom span {
  margin-left: 80px;
}

.page {
  width: 100%;
  //position: relative;
}

.flex {
  display: flex;
}

.navigation {
  width: 100%;
  //background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  height: 120px;
  position: absolute;
  //position: fixed;
  top: 0;
  z-index: 99;
}



.navigationText {
  z-index: 2;
  cursor: pointer;
  margin-right: 50px;
  line-height: 120px;
  width: 40%;
  justify-content: space-between;
  white-space: nowrap;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 18px;
}

.navigationText div {
  //margin-left: 10px;
  min-width: 84px;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  color: white;
  /*padding: 0 3px;*/
}
.dibucss{
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 6px;
  background-image: linear-gradient(to right, #0A70F6, #28C3FC);
  //left: -10px;
}
/* .contect {
  background-image: url(../assets/image/首页.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 1020px;
  width: 100%;
} */

.jump {
  margin: 0 auto;
  width: 40%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.jump div {
  width: 30%;
}

.choose {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #1c3b89;
  background: rgba(255, 255, 255, 0.6);
  height: 88px;
  font-weight: 500;
}

.name {
  width: 60% !important;
  text-align: center;
}

.foot {
  width: 100%;
  background-color: #272A2F;
  justify-content: center;
  box-sizing: border-box;
  padding: 70px 0;
}

.leftText {
  font-size: 14px;
  color: #5D6169;
  img{
    width: 173px;
    height: auto;
  }
}
.updownauto{
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  //justify-content:center;
}
.line {
  margin-top: 20px;
  width: 344px;
  height: 1px;
  background-color: #3B4048;
}

.left {
  //margin-left: 80px;
  //margin-top: 40px;
}
.updownauto{
  .business:nth-child(1){
    margin-top: 0;
  }
}
.business {
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.12);
  /* border: 6px solid #35383D; */
  border-radius: 2px;
  margin-top: 20px;
  color: #FFFFFF;
}

.business span {
  margin-left: 5px;
  font-weight: bold;
}

.btn {
  margin-left: 90px;
  cursor: pointer;
}

.right {
  width: 40%;
  margin-left: 120px;
}

.rightContect {
  width: 95%;
  font-size: 16px;
  color: #ffffff;
}

.contecttext div {
  width: 25%;
}
.contecttext{
  flex-direction: row;
  justify-content: center;
  //align-items: center;
}
.longLine {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: #3B4048;
}

.text {
  cursor: pointer;
  font-size: 14px;
  color: #5D6169;
  white-space: nowrap;
  text-align: left;
  p:nth-child(1){
    font-size: 16px !important;
    color: #ffffff !important;
    margin-bottom: 30px;
    margin-top: 0px;
  }
  p{
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}

.text div {
  //margin-top: 10px;
}
//.to:hover{
//  color:#FFBA00!important;
//}
</style>
