

<template>
  <div class="page" ref="outhome">
    <div class="navigation" :style="{background:bgcolor,width:outwidth}">
      <div class="navigationImg">
        <img :src="logo" class="logo">
        <span class="xuzhou">[&nbsp;徐州&nbsp;]</span>
      </div>
      <div class="flex navigationText">


        <div class="overhidden" v-for="(item,index) in mapList" :key="index" @click="newjumpTo(index,'')">
          <el-dropdown placement='top' :tabindex="item.index">
            <span class="el-dropdown-link">
              <template>
                {{item.title}}
              </template>
              <div v-if="colorindex == index" class="dibucss"></div>
            </span>
            <el-dropdown-menu style="top: 121px !important;" class="tophang" slot="dropdown" v-if="item.childTitle.length > 0">
              <el-dropdown-item   v-for="(itemtwo,indextwo) in item.childTitle" :key="itemtwo.id" @click.native="handleCommand(itemtwo.id,index)">{{itemtwo.name}}</el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu>
              <el-dropdown-item></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!--    悬浮窗-->
    <div class="xuanfu">
      <div v-for="(item,index) in xuanfulist" @click="gotop(index)" @mouseenter="mouseover(index)" @mouseleave="mouseLeave(index)"
           :key="index"
           :class="[xuanindex ==index?'greenxuan':'', 'hoverone']">
        <el-image
            :src="xuanindex ==index?item.url2:item.url1"
            fit="contain"></el-image>
      </div>
      <div class="weiab" v-if="xuanindex == 0">
        <!--      <div class="weiab">-->
        <p>联系微信</p>
        <img src="../assets/weixin.png" alt="">
      </div>
      <div class="phoneab"  v-if="xuanindex == 1">
        <span>联系电话：</span>
        <span>15605206608</span>
      </div>
    </div>
    <!--    路由位置-->
    <div style="margin-bottom: 150px; " class="body goverment" v-if="look">
<!--      <div class="page" :style="{ backgroundImage: 'url(' + banner + ')' }"></div>-->
      <div class="page">
        <el-carousel
            :autoplay="autoplay"
            :interval="interval"
            :initial-index="initialIndex"
            ref="carousel"
            @change="onChange"
        >
<!--          sss-->
          <el-carousel-item v-for="item in banner" :key="item.id">
            <video-player
                class="video-player vjs-custom-skin"
                style="width: 100%;height: 100%;"
                v-if="item.banner.indexOf('mp4') !== -1"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
                :x5-video-player-fullscreen="true"
                @play="onPlayerPlay($event)"
                @ended="onPlayerEnded($event)"
                @loadeddata="onPlayerLoadeddata($event)"
            >
            </video-player>
            <img class="banbg" style="width: 100%;height: 100%;" v-else :src="item.banner" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div>
        <div class="contect">
          <div class="title">当前位置：建筑智能化>{{ title }}</div>
          <div class="line"></div>
          <div class="flex introduce">
            <!--                    <div class="options">-->
            <!--                        <div style="padding-top: 20px;padding-right: 20px;">-->
            <!--                            <div class="h3">产品服务</div>-->
            <!--                            <div class="h3Line"></div>-->
            <!--                            <div v-for="(item, index) in productServiceDtoList" :key="'index1' + index">-->
            <!--                                <div style="margin-top:20px; width: 50%;" @click="goComponent(item.id)"-->
            <!--                                    :style="{borderBottom:(id == item.id ? '1px solid grey' : 'none')}" class="name">{{-->
            <!--                                    item.name }}</div>-->
            <!--                            </div>-->
            <!--                        </div>-->

            <!--                        <div style="padding-bottom:20px ; margin-top: 20px;">-->
            <!--                            <div class="h3" @click="goCase">应用案例</div>-->
            <!--                            <div class="h3Line"></div>-->
            <!--                        </div>-->

            <!--                    </div>-->
            <div class="options">
              <div class="chanpintext">产品服务</div>
              <div @click="goComponent(item.id)" v-for="item in productServiceDtoList"
                   :class="[id == item.id ? 'grenying' : '','newname']">
                <img class="uiconw" :src="id == item.id ?item.checkedIcon:item.noCheckedIcon" alt="">
                {{ item.name }}
                <br/>
              </div>
              <div style="padding-bottom: 30px; white-space: nowrap; margin-top: 20px;">
                <div class="chanpintext" @click="goCasetwo">
                  应用案例
                  <template v-if="yingtoal>0">（{{ yingtoal }}）</template>
                  <template v-if="yingtoal>0"><span class="chakan">查看>>></span></template>
                </div>
              </div>
            </div>
            <div class="optionText" v-if="Show">
              <div class="title">{{ introduce.name }}</div>
              <div class="line2"></div>
              <div v-html="introduce.introduce" class="introduceHtml"></div>
            </div>
            <div class="optionText" v-else>
              <div class="title">
                <span>应用案例</span>
                <div class="biaoqian">
                  <span @click="qiecolor(99,'全部')" :class="[qiecolort == 99 ?'bluecolor':'']">全部</span>
                  <span @click="qiecolor(index,item.name)" :class="[qiecolort == index ?'bluecolor':'']"
                        v-for="(item,index) in productServiceDtoList" :key="item.id">{{ item.name }}</span>
                </div>
              </div>
              <!--                        <div class="line3"></div>-->
              <div class="flexDiv">
                <!--                            <div class="applicationCasesList" v-for="item in applicationCasesList">-->
                <!--                                <div class="applicationCasesDiv">-->
                <!--                                    <img :src="item.pic" alt="">-->
                <!--                                    <div class="span">{{item.name}}</div>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <template v-for="item in applicationCasesList">
                  <div class="applicationCasesDiv" @click="yinyong(item.id)">
                    <img :src="item.pic" alt="">
                    <div class="detailbomtext">
                      {{ item.name }}
                      <div>
                        <span class="bomspan">{{ item.productServiceName }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 150px; " class="body goverment" v-if="!look">
      <div class="detailbottom">
        <div class="titletop nowhei">当前位置：建筑智能化>{{ title }} <span @click="backlist" class="backlist">返回</span></div>
        <div class="detailrigh" v-html="detailsin"></div>
      </div>
    </div>
    <div class="foot flex">
      <div class="left flex">
        <div class="leftText updownauto">
          <img :src="allmes.bottomLogo" alt="">
          <div class="zixunphone">
            <p>咨询电话</p>
            <p>{{ allmes.hotLine }}</p>
            <!--            <p>15605206608</p>-->
          </div>
        </div>
        <div class="btn updownauto">
          <div class="business flex" @click="goCallme">
            <img src="../assets/bomone.png" alt="">
            <span>合作咨询</span>
          </div>
          <div class="business flex" @click="toAbout">
            <img src="../assets/bomtwo.png" alt="">
            <span>主营业务</span>
          </div>
        </div>
      </div>
      <div class="right flex">
        <div class="rightContect">
          <div class="contecttext flex">
            <div class="text" v-for="(item,index) in mapList" v-if="index == 1 || index == 2 || index == 3 || index == 4" :key="index">
              <p> {{item.title}}</p>
              <p v-for="(itemtwo,indextwo) in item.childTitle" @click="handleCommand(itemtwo.id,index)" v-if="indextwo < 4" :key="itemtwo.id">{{itemtwo.name}}</p>
            </div>


            <!--            <div class="text">-->
            <!--              <p>数字政府</p>-->
            <!--              <p @click="jumpTo('government')" class="to">数字政府</p>-->
            <!--              <p @click="jumpTo('industrial')" class="to">数字工业</p>-->
            <!--              <p @click="jumpTo('building')" class="to">建筑智能化</p>-->
            <!--            </div>-->
            <!--            <div class="text">-->
            <!--              <p>数字工业</p>-->
            <!--              <p @click="valueTo('industrial','ERP企业管理系统')" class="to">ERP企业管理系统</p>-->
            <!--              <p @click="valueTo('industrial','MES制造执行系统')" class="to">MES制造执行系统</p>-->
            <!--              <p @click="valueTo('industrial','数据采集与监视控制系统')" class="to">数据采集与监视控制系统</p>-->
            <!--              <p @click="valueTo('industrial','数字孪生')" class="to">数字孪生</p>-->
            <!--            </div>-->
            <!--            <div class="text">-->
            <!--              <p>建筑智能化</p>-->
            <!--              <p @click="valueTo('building','建筑设备自动化系统')" class="to">建筑设备自动化系统</p>-->
            <!--              <p @click="valueTo('building','通信自动化系统')" class="to">通信自动化系统</p>-->
            <!--              <p @click="valueTo('building','楼宇自控系统')" class="to">楼宇自控系统</p>-->
            <!--              <p @click="valueTo('building','安全防范自动化系统')" class="to">安全防范自动化系统</p>-->
            <!--            </div>-->
            <!--            <div class="text">-->
            <!--              <p>关于点创</p>-->
            <!--              <p @click="valueTo('about','companyIntro')" class="to">公司简介</p>-->
            <!--              <p @click="valueTo('about','leaderMessage')" class="to">领导寄语</p>-->
            <!--              <p @click="valueTo('about','goHonor')" class="to">荣誉资质</p>-->
            <!--              <p @click="valueTo('about','corporateCulture')" class="to">企业文化</p>-->
            <!--            </div>-->
          </div>
        </div>

      </div>
    </div>
    <div class="bottom">
      <span>{{allmes.recordNo}} {{allmes.copyright}}</span>
    </div>
  </div>
</template>

<script>
// //防止页面后退  使用在vue时 挂载到mounted中
// history.pushState(null, null, document.URL);
// window.addEventListener('popstate', function () {
//   history.pushState(null, null, document.URL);
// });
const axios = require("axios")
export default {
  data() {
    return {
      xuanindex:99,
      xuanfulist:[{url1:require('../assets/erwei1.png'),url2:require('../assets/erwei2.png')},{url1:require('../assets/shouji1.png'),url2:require('../assets/shouji2.png')},{url1:require('../assets/up.png'),url2:require('../assets/up.png')}],
      colorindex:3,
      value: '/',
      logo:'',
      picIndex:'',
      bottomList:[],
      mapList:'',
      bottomLogo:'',
      contactsList:'',
      allmes:'',
      pushadress:'',
      bgcolor:'rgba(0, 0, 0, 0.1)',
      outwidth:'100%',
      parentid:'',
      galleryThumbs: {},
      newList: [],
      autoplay: true, //自动播放
      interval: NaN, //轮播时常
      initialIndex: 0, //从下标第几个开始轮播
      duration: NaN, //计算总时长
      banner: [],    //从后端获取来的轮播图数据

      //vue-video-play  播放器默认的设置
      playerOptions: {
        autoplay: true, //如果true,浏览器准备好时开始回放。实现自动播放，autoplay、muted都设置为true
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "这里是你视频的地址", //url地址
          },
        ],
        width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
      productServiceDtoList: [],
      title: '产品服务',
      id: '',
      introduce: '',
      Show: true,
      productList: [],
      applicationCasesList: [],
      shaiying: [],
      show: true,
      index: '',
      imgUrl: '',
      yingtoal: 0,
      qiecolort: 99,
      detailsin:'',
      detailslook:true,
      look:true,
      querychild:'',
    }

  },

  watch: {
    $route(to, from) {
      // this.getPath()
      if (this.$route.query.id) {
        this.goComponent(this.$route.query.id);
      }else{
        this.goComponent(this.querychild);
      }
    },
    productServiceDtoList(){
      this.goComponent(this.querychild);

    },
    querychild(){
      this.goComponent(this.querychild);

    },
    Show(){
    }
  },
  mounted() {
    this.getDate()
    this.getData()
    this.goCase()
    this.querychild = this.$route.query.id?this.$route.query.id:''
    this.outwidth = this.$refs.outhome.offsetWidth + 'px'
    window.onresize = () => {
      this.outwidth = this.$refs.outhome.offsetWidth + 'px'
    }
    // this.onChange(0)
  },
  created() {
    this.parentid=''
    // this.getPath()
    // window.addEventListener("load", () => {
    //   this.$router.push({
    //     path: this.$route.path
    //   })
    //   // this.colorindex = 99
    //   // this.getPath()
    // });
  },
  methods: {
    // 监听轮播图改变
    onChange(index) {
      console.log('index',index)
      // 如果是视频,就等播放完再进行下一个
      if (this.banner[index].banner.indexOf("mp4") != -1) {
        this.playerOptions["sources"][0]["src"] = this.banner[index].banner; //把获取来的视频赋值给sources的src
        this.$refs.videoPlayer[0].player.src(
            this.playerOptions["sources"][0]["src"]
        ); // 重置进度条,再次轮播时让视频重新播放
        this.interval = this.duration * 1000;
        this.autoplay = false;
        this.textlook = false
      }else{
        this.textlook = true
      }
    },
    onPlayerEnded(player) {
      if(this.banner.length == 1 && this.banner[0].banner.indexOf("mp4")){
        this.playerOptions["loop"] = true //如果只有一个视频，则循环播放
      }
      //视频播放停止时候触发的事件
      this.autoplay = true;
      this.interval = 3000;
    },
    onPlayerLoadeddata(player) {
      // 获取资源总时长
      this.duration = player.cache_.duration;
    },
    onPlayerPlay(player) {
      player.play();
    },
    onSwiper() {

    },
    onSlideChange() {

    },
    backlist(){
      this.look = true
      this.getUser(true)
    },

    yinyong(val){
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.look = false
      this.getUser(false)
      this.$http({
        url: 'productApi/queryApplicationCasesDetail?applicationCasesId='+ val +'',
        method: 'post',
      }).then((res) => {
        this.detailsin = res.data.introduce
      })
      // this.$router.push({
      //   path: '/details',
      //   query: {
      //     id: val
      //   }
      // })
    },
    qiecolor(val, name) {
      this.qiecolort = val
      if (name == '全部') {
        this.applicationCasesList = this.shaiying
      } else {
        let arr = this.shaiying.filter(item => {
          return item.productServiceName == name
        })
        this.applicationCasesList = arr
      }
    },
    getData() {
      this.$http({
        url: 'productApi/queryProductService?type=3',
        method: 'post'
      }).then((res) => {
        console.log('res',res)
        this.banner = res.data.digitalSimulationList
        if(this.banner.length == 1 && this.banner[0].banner.indexOf("mp4")){
          this.playerOptions["sources"][0]["src"] = this.banner[0].banner; //把获取来的视频赋值给sources的src
        }
        this.productServiceDtoList = res.data.productServiceList
        if (this.productServiceDtoList&&this.productServiceDtoList.length > 0 && this.querychild == '') {
          this.goComponent(this.productServiceDtoList[0].id)
        }
        // for (let i = 0; i < this.productServiceDtoList.length; i++) {
        //     this.introduce = this.productServiceDtoList[0]
        //     this.imgUrl = this.introduce.picUrlList[0].fileUrl
        //     this.id = !this.id ? this.productServiceDtoList[0].id : this.id
        //     this.goComponent(this.productServiceDtoList[0].id)
        // }
      })

    },
    goCasetwo() {
      this.title = '应用案例'
      this.show = false
      this.Show = false
      this.id = ''
      this.qiecolor(99,'全部')

    },
    goCase() {
      this.$http({
        url: 'productApi/queryApplicationCases?page=1&limit=10&type=3',
        method: 'post'
      }).then((res) => {
        this.applicationCasesList = res.data
        this.shaiying = res.data
        this.yingtoal = res.count

      })

      // this.$http({
      //     url: 'productApi/queryApplicationCasesDetail?type=3&applicationCasesId=' + val,
      //     method: 'post',
      // }).then((res) => {
      //     this.applicationCasesList = res.data
      // })

    },
    goComponent(id) {
      this.id = id
      this.title = '产品服务'
      this.show = true
      this.Show = true
      // for (let i = 0; i < this.productServiceDtoList.length; i++) {
      //   if (id == this.productServiceDtoList[i].id) {
      //     this.introduce = this.productServiceDtoList[i]?this.productServiceDtoList[i]:this.productServiceDtoList[0]
      //     // console.log(this.introduce);
      //   }
      // }
      if(this.productServiceDtoList.length > 0){
        let arr = this.productServiceDtoList.filter(item => (item.id == id));
        this.introduce = arr[0]?arr[0]:this.productServiceDtoList[0]
        this.id=this.id==''?this.productServiceDtoList[0].id:this.id
      }
    },
    getUser(val){
      if(val){
        this.bgcolor='rgba(0, 0, 0, 0.1)'
      }else{
        this.bgcolor='#000940 !important'
      }
    },
    gotop(index){
      if(index == 2){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    },
    mouseover(val){
      this.xuanindex = val
    },
    mouseLeave(val){
      this.xuanindex = 99
    },
    getDate(){
      this.$http({
        url:'indexApi/queryIndexInfo',
        method:'post',
      }).then((res)=>{
        this.logo = res.data.logo
        this.mapList = res.data.mapList
        this.allmes = res.data
      })

      // this.$http({
      //   url:'footApi/queryFootInfo',
      //   method:'post',
      // }).then((res)=>{
      //   this.bottomList = res.data
      //   this.bottomLogo = res.data.bottomLogo
      //   this.contactsList = res.data.contactsList
      // })

    },
    goCallme(){
      this.$router.push({
        path:'contact'
      })
    },
    toAbout(){
      this.$router.push({
        path:'government'
      })
    },
    handleCommand(id,val) {
      this.parentid=id
      this.querychild=id
      this.getUser(true)
      this.colorindex = val
      if(val == 4){
        // this.newhandleCommand(id)
        this.valueTo('about', '')
      }else{
        this.newjumpTo(val,id)
        // this.$router.push({
        //   path: this.newjumpTo(val),
        //   query:{id:id}
        // })
      }
    },
    newjumpTo(val,id){
      // console.log('点击跳转',this.$refs.childData)
      this.getUser(true)
      this.colorindex = val
      let data = ''
      switch (val){
        case 0:
          data = '/'
          break;
        case 1:
          data = '/government'
          break;
        case 2:
          data = '/industrial'
          break;
        case 3:
          data = '/building'
          break;
        case 4:
          data = '/about'
          break;
        case 5:
          data = '/contact'
          break;
      }
      this.pushadress = data
      this.$router.push({
        path: data,
        query:{id:id}

      })
      return data
    },
    newhandleCommand(command) {
      let data= ''
      if(command == 1){
        data = 'companyIntro'
      }else if(command == 2){
        data = 'leaderMessage'
      }else if(command == 3){
        data = 'goHonor'
      }else{
        data = 'corporateCulture'
      }
      this.valueTo('about', data)
    },

    getPath(v1, v2) {

      this.value = this.$route.path
      // console.log('this.value-',this.value ,'this.$route.path',this.$route.path)
      this.$router.push({
        path: this.value == '/'?'/building':this.value
      })
      let findex = 0
      switch (this.$route.path){
        case '/':
          this.colorindex = 0
          break;
        case '/government':
          this.colorindex = 1
          break;
        case '/industrial':
          this.colorindex = 2
          break;
        case '/building':
          this.colorindex = 3
          break;
        case '/about':
          this.colorindex = 4
          break;
        case '/contact':
          this.colorindex = 5
          break;
        default:
          this.colorindex = this.colorindex
          break;
      }
    },
    jumpTo(val) {
      this.getUser(true)
      this.$router.push({
        path: val
      })
    },

    valueTo(val,value){
      this.getUser(true)
      this.$router.push({
        path:val,
        query:{
          value:value
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
//视频 33
/deep/ {
  /* 进度条 */
  .vjs-tech{
    object-fit:fill;
    overflow:hidden;
  }
  .vjs-control-bar,.vjs-control,.vjs-progress-control {
    display: none !important;
  }
  .video-js{
    height: 100% !important;
    padding-top: 0 !important;
  }
  .el-carousel{
    height: 100%;
    .el-carousel__container{
      height: 100%;
    }
  }
  .el-carousel__arrow--left{
    display: none;
  }
  .el-carousel__arrow--right{
    display: none;
  }
  .el-carousel__indicators--horizontal{
    display: none;
  }
}

//***
.goverment{
  .line {
    width: 80% !important;
    height: 1px !important;
    background-color: #EBEEF3 !important;
  }
}
.bomspan{
  box-sizing: border-box;
  background: #F5F5FB;
  border-radius: 2px;
  color: #64728A;
  font-size: 14px;
  padding: 7px;
  margin-top: 15px;
  display: inline-block;
}
.detailbomtext{
  width: 100%;
  box-sizing: border-box;
  padding: 14px 13px;
  font-size: 18px;
  color: #4C5462;
}
.applicationCasesDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  margin-left: 3.3%;
  height: auto;
  border-radius: 10px;
  border: 1px solid #E9EDF1;
  margin-top: 40px;
  cursor: pointer;
  //transform:scale(1);
  transition: all 0.3s;
  img{
    width: 100%;
    height: 220px;
    border-radius: 10px 10px 0 0;
  }
}
.applicationCasesDiv:hover{
  transform:scale(1.1);
  -webkit-box-shadow: #ccc 0px 10px 10px;
  -moz-box-shadow: #ccc 0px 10px 10px;
  box-shadow: #ccc 0px 10px 10px;
  transition: all 0.3s;

}

.applicationCasesDiv:nth-child(3n+1){
  margin-left: 0 !important;
}
.grenying{
  background: linear-gradient(90deg, #08215A, #092D81) !important;
  border: 1px solid #092D81;
  color: white !important;
}
.newname{
  width: 100%;
  height: 55px;
  line-height: 55px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  margin-top: 12px;
  box-sizing: border-box;
  padding: 0 16px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  .uiconw{
    width: 30px !important;
    height: auto;
    vertical-align: middle;
    margin-right: 17px;
  }
}
.chanpintext{
  margin-bottom: 10px;
  color: #08215A !important;
  font-size: 24px;
  font-family: Alibaba PuHuiTi;
  font-weight: 500;
  height: 31px;
  line-height: 31px;
  .chakan{
    font-size: 16px;
    float: right;
    color: #3E77FF !important;
  }
}
.backlist{
  float: right;
  font-size: 14px;
  cursor: pointer;
}
.detailrigh{
  box-sizing: border-box;
  /deep/ img{
    max-width: 100% !important;
  }
  //padding-left: 120px;
}
.detailbottom{
  width: 60%;
  min-height: 800px;
  //background: pink;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 120px;
}
.bluecolor{
  background: #F1F5FF;
  border: 1px solid #3E77FF !important;
}
.biaoqian{
  display: inline-block;
  vertical-align: 3px;
  span{
    display: inline-block;
    width: 100px;
    height: 30px;
    box-sizing: border-box;
    padding: 0 5px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #D2D3E4;
    border-radius: 6px;
    font-size: 14px;
    margin-left: 15px;
    cursor: pointer;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    float: left;
    margin-bottom: 5px;
  }
  span:hover{
    background: #F1F5FF;
    border: 1px solid #3E77FF;
    transition:0.3s;
  }
  span:nth-child(1){
    //margin-left: 24px;
  }
}
.titletop {
  height: 30px;
  line-height: 30px;
  color: #A0A8B5;
  font-size: 14px;
  margin: 15px 0;
}
.nowhei{
  margin: 40px 0 40px -120px;
}
//********
html,
body {
  width: 100%;
}

.body {
  max-width: 100%;
  overflow: hidden;
}
.display{
  display: none!important;


}

// .swiper-button-next {
//     top: 110px;
//     position: absolute;
//     color: #ffffff;
//     width: 26px;
//     height: 100px;
//     border-radius: 2px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

// }

// .swiper-button-prev {
//     top: 110px;
//     position: absolute;
//     color: #ffffff;
//     width: 26px;
//     height: 100px;
//     border-radius: 2px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
.swiper-button-prev:after{
  font-size: 16px!important;
}
.swiper-button-next:after{
  font-size: 16px!important;
}
.introduceHtml{
  margin-top: 50px;
  width: 85%;
  /deep/ img {
    max-width: 100% !important;
  }
}

.thumb-example {
  height: 480px;
  background-color: #000;
}

.swiperBottom {
  width: 100% !important;
}

.swiper:hover{
  .display{
    display: block!important;
  }
  .right{
    margin-right: -20px;
    // margin-top: 1px;
  }
}

.swiper {

  .swiper-slide {
    background-size: cover;
    background-position: center;

    &.slide {
      width: 102px;
      height: 68px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &.slideTop {
      width: 350px;
      height: 250px;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  &.gallery-top {
    height: 80%;
    width: 100%;
  }

  &.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  &.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}










.rightTitle {
  // width: 20%;
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #ABABAB;
  line-height: 24px;
  white-space: nowrap;
}

.applicationCasesList {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  .applicationCasesDiv {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  img {
    width: 389px !important;
    height: 240px;
    border: 5px solid #FFFFFF;

  }

  .span {
    margin-top: 6px;
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #4C5462;
  }
  .applicationCasesDiv img:hover{
    border: 5px solid gray;
  }

}

.h3 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 700;
  color: #4C5462;
}

.h3Line {
  margin-top: -8px;
  height: 8px;
  width: 30px;
  background-color: #FFBA00;
}

.flexDiv {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  //justify-content: space-between;

}

.introduceContect {
  margin-left: 100px;
  display: flex;
  justify-content: flex-start;
  width: 33.33%;
  justify-content: center;
  align-items: center;


  .name {
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
  }

  .line {
    width: 26px;
    height: 4px;
    background-color: #ffffff;
  }

  .text {
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    display: none;
  }
}

.introduceContect:hover {
  justify-content: flex-start;

  .name {
    margin-top: 50px !important;
  }

  .icon {
    display: none;
  }

  .text {
    display: block;
  }
}

.Image {
  margin-top: 50px;
  width: 100%;
  justify-content: space-between;
  margin-left: -100px;


  .line {
    width: 26px;
    margin-bottom: 10px;
  }

  .name {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.image {
  margin-top: 50px;
  width: 80%;
}

.titleText {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #4C5462;
  line-height: 24px;
  margin-left: 20px;
}

.optionTitle {
  white-space: nowrap;
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #ABABAB;
  line-height: 24px;
}

.page {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
}

.contect {
  width: 85%;
  margin-left: 15%;
}

.flex {
  display: flex;
}

.title {
  height: 30px;
  line-height: 30px;
  color: #A0A8B5;
  font-size: 14px;
  margin: 15px 0;
}

.line {
  width: 80%;
  height: 1px;
  background-color: #EBEEF3;
}

.introduce {
  margin-top: 30px;
  display: flex;

  .leftArrow {
    height: 40px;
    line-height: 40px;
  }

  .rightArrow {
    height: 40px;
    line-height: 40px;
  }

  .imgUrl {
    width: 320px;
    height: 200px;
  }

  .image {
    margin-top: 0 !important;
    display: flex;

    img {
      width: 60px;
      height: 40px;
      margin-left: 5px;
    }
  }

  .synopsis {
    margin-left: 10px;
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #4C5462;
    line-height: 24px;
  }
}


.options {
  height: auto;
  width: 280px!important;
  //background: #F5F6FA;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
  .Line {
    width: 23%;
    height: 1px;
    background-color: #4C5462;
  }

  .h3 {
    margin-left: 20px;
  }

  .h3Line {
    margin-left: 20px;
  }

  .name {
    line-height: 40px;
    margin-left: 30px;
    font-size: 14px;
    display: inline-block;
    display: inline;
    width: auto;
    height: auto;
  }
}



.optionText {
  margin-left: 5%;
  width: 90%;

  .Image {
    margin-top: 50px;
    width: 100%;
    justify-content: space-between;
    margin-left: -100px;


    .line {
      width: 26px;
      margin-bottom: 10px;
    }

    .name {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .title {
    width: 80%;
    height:auto;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    //font-weight: 500;
    color: #4C5462;
    white-space: nowrap;
    span:nth-child(1){
      float: left;
    }
  }


}

.line2 {
  width: 89%;
  height: 1px;
  background-color: #EBEEF3;
}
.line3{
  width: 91%;
  height: 1px;
  background-color: #EBEEF3;
}
//***
.overhidden{
  overflow: hidden;
}
//.el-dropdown-menu{
//  margin-top: -30px!important;
//}
.el-dropdown-menu{
  margin-top: -85px !important;
}
.weiab{
  width: 160px;
  height: 169px;
  background: white;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -168px;
  top: 0;
  p{margin-bottom: 13px;}
  img{
    width: 95px;
    height: 95px;
  }
}
.phoneab{
  width: 235px;
  height: 80px;
  position: absolute;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -243px;
  top: 80px;
  span:nth-child(1){
    font-size: 14px;
    color: #9AA8C1;
    z-index: 100;
  }
  span:nth-child(2){
    font-size: 18px;
    color: #3E77FF;
    z-index: 100;
  }
}
.greenxuan{
  background: #3E77FF !important;
  transition: all 0.3s;
}
.hoverone{
  width: 80px;
  height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  .el-image{
    width: 36px;
  }
}
.xuanfu{
  width: 80px;
  height: 240px;
  background: #ffffff;
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 99;
}
.navigationImg {
  margin: auto 0;
  margin-left: 50px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
}
.logo{
  width: 140px;
  max-height: 90px;
}
p{margin: 0;}
.zixunphone{
  p:nth-child(1){
    margin-top: 30px;
    font-size: 14px;
    margin-bottom: 10px;
    color: #5D6169;
  }
  p:nth-child(2){
    font-size: 24px;
    color: white;
  }
}
.xuzhou{
  position: absolute;
  right: -90px;
  bottom: 0px;
  color: white;
  font-size: 20px;
}

.el-dropdown-link {
  font-size: 18px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.bottom {
  width: 100%;
  height: 80px;
  background-color: #1A1B1D;
  line-height: 80px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #525559;
  text-align: center;
}

.bottom span {
  margin-left: 80px;
}

.page {
  width: 100%;
  //position: relative;
}

.flex {
  display: flex;
}

.navigation {
  width: 100%;
  //background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  height: 120px;
  position: absolute;
  //position: fixed;
  top: 0;
  z-index: 99;
}



.navigationText {
  z-index: 2;
  cursor: pointer;
  margin-right: 50px;
  line-height: 120px;
  width: 40%;
  justify-content: space-between;
  white-space: nowrap;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 18px;
}

.navigationText div {
  //margin-left: 10px;
  min-width: 84px;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  color: white;
  /*padding: 0 3px;*/
}
.dibucss{
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 6px;
  background-image: linear-gradient(to right, #0A70F6, #28C3FC);
  //left: -10px;
}
/* .contect {
  background-image: url(../assets/image/首页.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 1020px;
  width: 100%;
} */

.jump {
  margin: 0 auto;
  width: 40%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.jump div {
  width: 30%;
}

.choose {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #1c3b89;
  background: rgba(255, 255, 255, 0.6);
  height: 88px;
  font-weight: 500;
}

.name {
  width: 60% !important;
  text-align: center;
}

.foot {
  width: 100%;
  background-color: #272A2F;
  justify-content: center;
  box-sizing: border-box;
  padding: 70px 0;
}

.leftText {
  font-size: 14px;
  color: #5D6169;
  img{
    width: 173px;
    height: auto;
  }
}
.updownauto{
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  //justify-content:center;
}
.line {
  margin-top: 20px;
  width: 344px;
  height: 1px;
  background-color: #3B4048;
}

.left {
  //margin-left: 80px;
  //margin-top: 40px;
}
.updownauto{
  .business:nth-child(1){
    margin-top: 0;
  }
}
.business {
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.12);
  /* border: 6px solid #35383D; */
  border-radius: 2px;
  margin-top: 20px;
  color: #FFFFFF;
}

.business span {
  margin-left: 5px;
  font-weight: bold;
}

.btn {
  margin-left: 90px;
  cursor: pointer;
}

.right {
  width: 40%;
  margin-left: 120px;
}

.rightContect {
  width: 95%;
  font-size: 16px;
  color: #ffffff;
}

.contecttext div {
  width: 25%;
}
.contecttext{
  flex-direction: row;
  justify-content: center;
  //align-items: center;
}
.longLine {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: #3B4048;
}

.text {
  cursor: pointer;
  font-size: 14px;
  color: #5D6169;
  white-space: nowrap;
  text-align: left;
  p:nth-child(1){
    font-size: 16px !important;
    color: #ffffff !important;
    margin-bottom: 30px;
    margin-top: 0px;
  }
  p{
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}

.text div {
  //margin-top: 10px;
}
//.to:hover{
//  color:#FFBA00!important;
//}
</style>
