<template>
    <div>
        <div class="page" :style="{ backgroundImage: 'url(' + banner + ')' }"></div>
<!--        <div class="contect">-->
<!--            <div class="title">当前位置：联系我们</div>-->
<!--            <div class="line"></div>-->
<!--            <div class="flex" style="margin-top: 30px;">-->
<!--                <div class="callMe">-->
<!--                    <div class="h3">-->
<!--                        联系我们-->
<!--                    </div>-->
<!--                    <div class="callMeLine"></div>-->
<!--                </div>-->
<!--                <div style="margin-left: 100px; width: 70%;">-->
<!--                    <span class="name">{{list.name}}</span>-->
<!--                    <div class="line2"></div>-->
<!--                    <div class="slogan">{{list.message}}</div>-->
<!--                    <div>-->
<!--                        <ul class="ul">-->
<!--                            <li v-for="item in contactsList">{{item.name}}:{{item.tel}}</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                    <div class="address">地址：{{list.address}}<br> 邮编：{{list.postalCode}}</div>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
      <p class="lianxip">联系方式</p>
      <div class="newcontext">
        <div class="liandiv">
          <img class="lianimg1" src="../assets/smallwei.png" alt="">
          <span>微信：</span>
          <img class="lianimg2" :src="contactUs.weChatCode" alt="">
        </div>
        <div class="liandiv">
          <img class="lianimg3" src="../assets/smalldian.png" alt="">
          <span>电话：{{ contactUs.hotLine }}</span>
        </div>
        <div class="liandiv">
          <img class="lianimg4" src="../assets/smalldi.png" alt="">
          <span>地址：{{ contactUs.address }}</span>
        </div>
      </div>
      <div class="liubai">
              <img class="addressimg" :src="contactUs.addressPic" alt="">
      </div>
    </div>
</template>


<script>
    const axios = require('axios')
    export default{
        data() {
            return {
                list:[],
                contactsList:[],
                contactUs:[],
                banner:'',
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData(){
                this.$http({
                    url:'contactUs/queryContactUs',
                    method:'post'
                }).then((res)=>{
                  // console.log('联系我们',res)
                  this.banner = res.data.banner
                  this.contactUs = res.data.contactUs
                    // this.list = res.data
                    this.contactsList = res.data.contactsList
                })
            }
        },
    }
</script>


<style lang="scss" scoped>
.liubai{
  height: 600px;
}
.addressimg{
  width: 100%;
  height: 600px;
}
.lianxip{
  margin: 66px 0 20px 0;
  text-align: center;
  font-size: 24px;
  color: #2F2F2F;
}
.newcontext{
  width: 62%;
  margin: auto;
  height: auto;
  margin-bottom: 46px;
  display: flex;
  justify-content: space-between;
  .liandiv{
    height: 122px;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items:center;
    .lianimg1{
      width: 30px;
      height:auto;
      margin-right: 10px;
    }
    .lianimg2{
      width: 122px;
      height: 122px;
      margin-left: 23px;
    }
    .lianimg3{
      width: 24px;
      height: auto;
      margin-right: 23px;
    }
    .lianimg4{
      width: 20px;
      height: auto;
      margin-right: 12px;
    }
  }
}
//*******************
    .ul{
        margin-left: -20px;
    }
    .h3 {
    font-size: 18px;
    font-family: Alibaba PuHuiTi;
    font-weight: 700;
    color: #4C5462;
    padding-top: 35px;
}

.callMeLine {
    margin-top: -8px;
    height: 8px;
    width: 30px;
    background-color: #FFBA00;
}
.address {
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #4C5462;
    line-height: 24px;
}

ul li::marker {
    color: #FFBA00;
}

ul li {
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #4C5462;
    line-height: 36px;
}

.slogan {
    margin-top: 20px;
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #4C5462;
    line-height: 24px;
}

.line2 {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background-color: #EBEEF3;
}

.name {
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #4C5462;
}

.page {
    /*background: url('../assets/image/联系我们.png');*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
}

.contect {
    width: 85%;
    margin-left: 15%;
    margin-bottom: 50px;
}

.flex {
    display: flex;
}

.title {
    height: 30px;
    line-height: 30px;
    color: #A0A8B5;
    font-size: 18px;
    margin:15px 0 ;
}

.line {
    width: 80%;
    height: 1px;
    background-color: #EBEEF3;
}

.callMe {
    width: 280px;
    background: #F5F6FA;
    border-radius: 4px;
    padding-left: 17px;
    height: 97px;
}
</style>
