import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import pageIndex from '../views/page-index.vue'
import government from "../views/government.vue"
// import industrial from '../views/industrialnew.vue'
import building from '../views/building.vue'
import about from '../views/about.vue'
import contact from '../views/contact.vue'
import details from '../views/details.vue'
import Tran from '../views/governmentnew.vue'
import Yuop from '../views/industrialnew.vue'
import Buil from '../views/buildingnew.vue'

Vue.use(VueRouter)




const routes = [
  {
    path: '/',
    // name: 'home',
    component: HomeView,
    children:[
      {
        path:'/',
        component:pageIndex
    }
    // ,{
    //   name:'government',
    //     path:'government',
    //     component:government
    // }
    // ,{
    //   name:'industrial',
    //     path:'industrial',
    //     component:industrial
    // }
    // ,{
    //   name:'building',
    //     path:'building',
    //     component:building
    // }
    ,{
      name:"about",
      path:'about',
      component:about
      }, {
        path: 'contact',
        component: contact
      }
      , {
        path: 'details',
        component: details
      }
    ]
  },
  {
    path: '/government',
    name: 'government',
    component: () => import('@/views/governmentnew'),
    children:[]
  },
  {
    path: '/industrial',
    name: 'industrial',
    component: () => import('@/views/industrialnew'),
    children:[]
  },
  {
    path: '/building',
    name: 'building',
    component: () => import('@/views/buildingnew'),
    children:[]
  }


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
