<template>
<div class="details">
  <div v-html="lookhtml"></div>
</div>
</template>

<script>
export default {
  name: "details",
  data(){
    return{
      id:'',
      lookhtml:'',
    }
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
.details{
  min-height: 800px;
  width: 100%;
  background: pink;
}
</style>